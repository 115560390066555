import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import axios from "axios";
import { Container } from 'semantic-ui-react';

// Client
// import ClientNavBar from './client/layout/NavBar';
import LoginPage from './client/pages/login/LoginPage';
import ForgotPasswordPage from './client/pages/login/ForgotPasswordPage';
import ResetPasswordPage from './client/pages/login/ResetPasswordPage';
// import ClientProductListPage from './client/pages/product/ProductListPage';
// import ClientProductDocsPage from './client/pages/product/ProductDocsPage';

// Admin
// import NavBar from './admin/layout/NavBar';
import ProductListPage from './admin/pages/product/ProductListPage';
import ProductDocsPage from './admin/pages/product/ProductDocsPage';
import EditProductDocsPage from './admin/pages/product/EditProductDocsPage';
import TicketListPage from './admin/pages/ticket/TicketListPage';
import TicketInputPage from './admin/pages/ticket/TicketInputPage';
import TicketDetailsPage from './admin/pages/ticket/TicketDetailsPage';
import AdminTicketStatsPage from './admin/pages/ticket/AdminTicketStatsPage';
// import EmptyNavBar from './admin/layout/EmptyNavBar';
import FirstLoginPage from './admin/pages/user/FirstLoginPage';
import MainPage from './MainPage';

const userStr = localStorage.getItem("user");
const token = JSON.parse(userStr) && JSON.parse(userStr).jwt;
const user = JSON.parse(userStr) && JSON.parse(userStr).user;


axios.defaults.baseURL ='https://supportapi.visiumlabs.com';

axios.interceptors.request.use(
  config => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);


const AllRoutes = (props) => {
  return (
    <>
      {!user ?
        <>
          <Router>
            {/* <Route component={ClientNavBar} /> */}
            <Container  fluid>
              <Switch>
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/forgot-password' component={ForgotPasswordPage} />
                <Route exact path='/reset-password' component={ResetPasswordPage} />
                {/* <Route exact path='/product-list' component={ClientProductListPage} />
                <Route exact path='/product-docs/:productId' component={ClientProductDocsPage} />
                <Route exact path='/product-docs/:productId/:articleId' component={ClientProductDocsPage} /> */}
                <Route path='*' component={() => <Redirect to={"/login"} />} />
              </Switch>
            </Container>
          </Router>
        </> : user.firstLogin ?
          <>
            <Router>
              {/* <Route component={EmptyNavBar} /> */}
              <Container  fluid>
                <Switch>
                  <Route path='*' component={FirstLoginPage} />
                </Switch>
              </Container>
            </Router>
          </> :
          <>
            <Router>
              {/* <Route>
                <NavBar user={user} />
              </Route> */}
              <Container id='main-app-container' fluid>
                <Switch>
                  <Route exact path='/product-list' component={ProductListPage} />
                  <Route exact path='/product-docs/:productId' component={ProductDocsPage} />
                  <Route exact path='/product-docs/:productId/:articleId' component={ProductDocsPage} />
                  <Route exact path='/edit-product-docs/:productId' component={EditProductDocsPage} />
                  <Route exact path='/edit-product-docs/:productId/:articleId' component={EditProductDocsPage} />

                  <Route exact path='/main' component={MainPage} />
                  <Route exact path='/ticket-list' component={TicketListPage} />
                  <Route exact path='/admin-ticket-stats' component={AdminTicketStatsPage} />
                  <Route exact path='/new-ticket' component={TicketInputPage} />
                  <Route exact path='/edit-ticket/:id' render={(props) => <TicketInputPage {...props} isEdit={true} />} />
                  <Route exact path='/ticket-details/:id' component={TicketDetailsPage} />
                  
                  <Route path='*' component={() => <Redirect to={"/main"} />} />
                </Switch>
              </Container>
            </Router>
          </>
      }
    </>
  );
};

export default AllRoutes;
