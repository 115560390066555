import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import PageHeader from '../../layout/PageHeader';
import TicketErrorMessage from './components/TicketErrorMessage';
import TicketsTable from './components/TicketsTable';
import { Grid, Button, Icon, Form, Dimmer, Statistic } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  priorityDD, resolutionDD, ticketTypeDD, priorityBgColorMap, priorityFgColorMap, ticketIconMap,
  statusTextMap, resolutionTextMap
} from '../../constants';
import './ticket.css';



const TicketListPage = (props) => {
  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr) && JSON.parse(userStr).user;

  const [ticketList, setTicketList] = useState([]);
  const [clientDD, setClientDD] = useState([]);
  const [productDD, setProductDD] = useState([]);
  const [ticketListLoading, setTicketListLoading] = useState(false);
  const [isVisibleError, setIsVisibleError] = useState(false);
  const [statusCounts, setStatusCounts] = useState({ 'open': 0, 'inProgress': 0, 'fixPlanned': 0, 'waitingForCustomer': 0, 'closed': 0, 'total': 0 })

  const filterStr = sessionStorage.getItem('filters');
  const [filters, setFilters] = useState(JSON.parse(filterStr) || {
    client: [],
    product: [],
    priority: [],
    status: [],
    resolution: [],
    type: [],
    onlyMyTickets: user.role.type === 'authenticated' ? false : true,
    assignedToMe: false
  });
  const [sortParams, setSortParams] = useState({ field: 'createdAt', direction: 'DESC' });
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);


  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    setTicketListLoading(true);
    const fetchId = ++fetchIdRef.current;
    const startRow = pageSize * pageIndex;
    let queryStr = `?_start=${startRow}&_limit=${pageSize}&`;

    if (fetchId === fetchIdRef.current) {
      // filter params
      for (let f in filters) {
        if (f === 'onlyMyTickets') {
          queryStr += `onlyMyTickets=${filters[f]}&`;
        } else if (f === 'assignedToMe' && filters[f]) {
          queryStr += `assignedTo=${user.id}&`;
        } else if (filters[f].length) {
          for (let val of filters[f]) {
            queryStr += `${f}_in=${val}&`;
          }
        }
      }

      // sort params
      queryStr += `_sort=${sortParams.field}:${sortParams.direction}&`;

      return axios.get(`/tickets${queryStr}`)
        .then(res => {
          setIsVisibleError(false);
          setTicketList(res.data.data);
          setPageCount(Math.ceil(res.data.totalCount / pageSize));
          setStatusCounts(res.data.statusCounts);
        })
        .catch(err => {
          console.log('fetchData failed\n', err);
          setIsVisibleError(true);
        })
        .finally(() => setTicketListLoading(false));
    }

  }, [filters, sortParams]);


  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'ticketId' },
      { Header: 'Title', accessor: 'title' },
      { Header: 'Opened By', accessor: 'openedBy' },
      { Header: 'Assigned To', accessor: 'assignedTo' },
      { Header: 'Product', accessor: 'product' },
      { Header: 'Age', accessor: 'age' },
      { Header: 'Priority', accessor: 'priority' },
      { Header: 'Status', accessor: 'status' }
    ],
    []
  );


  const statusValues = useMemo(() => ['open', 'inProgress', 'fixPlanned', 'waitingForCustomer', 'closed'], []);


  const transformData = () => {
    return ticketList.map(t => {
      const priorityStyle = { padding: '4px 8px', borderRadius: '4px', border: '1px solid' };
      priorityStyle.backgroundColor = priorityBgColorMap[t.priority];
      priorityStyle.color = priorityFgColorMap[t.priority];
      let typeIcon = t.type === 'bug' ? <Icon name='bug' /> : t.type === 'feature' ? <Icon name='cog' /> : <Icon name='circle notched' />;
      let age = moment(t.closeDate).from(moment(t.createdAt), true);

      return {
        ticketId: <Link to={`/ticket-details/${t.id}`} >{`${t.ticketId}`}</Link>,
        title: <Link to={`/ticket-details/${t.id}`} >{typeIcon}{t.title}</Link>,
        openedBy: t.openedBy.firstName + ' ' + t.openedBy.lastName,
        assignedTo: t.assignedTo ? t.assignedTo.firstName + ' ' + t.assignedTo.lastName : '-',
        product: t.product.name,
        versionNo: t.versionNo,
        age: age,
        priority: <span className='capitalize' >{t.priority}</span>,
        status: <span>{statusTextMap[t.status]}</span>,
        resolution: t.status !== 'closed' ? '-' : <span>{resolutionTextMap[t.resolution]}</span>
      }
    })
  }


  const handleChangeStatus = (s) => {
    const idx = filters.status.indexOf(s);
    if (idx === -1) {
      setFilters(prevState => ({
        ...prevState,
        status: [...prevState.status, s]
      }));
    } else {
      setFilters(prevState => ({
        ...prevState,
        status: prevState.status.filter((_, i) => i !== idx)
      }));
    }
  }


  const toggleMyFilters = (event, { checked }) => {
    setFilters(prevState => ({
      ...prevState,
      onlyMyTickets: checked
    }));
  }


  const toggleAssignedToMe = (event, { checked }) => {
    setFilters(prevState => ({
      ...prevState,
      assignedToMe: checked
    }));
  }


  const toggleFilter = (filterKey, value) => {
    const idx = filters[filterKey].indexOf(value);
    if (idx === -1) {
      setFilters(prevState => ({
        ...prevState,
        [filterKey]: [...prevState[filterKey], value]
      }));
    } else {
      setFilters(prevState => ({
        ...prevState,
        [filterKey]: prevState[filterKey].filter((_, i) => i !== idx)
      }));
    }
  }


  const clearFilters = () => {
    setFilters({
      client: [],
      product: [],
      priority: [],
      status: [],
      resolution: [],
      type: [],
      onlyMyTickets: false,
      assignedToMe: false
    });
  }


  const getClientsDD = () => {
    return axios.get('/clients/dropdown')
      .then(res => {
        setClientDD(res.data);
      })
      .catch(err => console.log('getClientsDD failed', err));
  }


  const getProductsDD = () => {
    return axios.get('/products/dropdown')
      .then(res => {
        setProductDD(res.data);
      })
      .catch(err => console.log('getProductsDD failed', err));
  }


  useEffect(() => {
    sessionStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);


  // mount
  useEffect(() => {
    getProductsDD();
    if (user.role.type === 'authenticated') {
      getClientsDD();
    }
  }, []);


  return (
    <Grid>
      <PageHeader title='Tickets' description="Ticket list page" icon='sticky note outline'>
        <Button icon color='orange' as={Link} to='/new-ticket' >
          <Icon name='plus' />
          Open New Ticket
        </Button>
      </PageHeader>
      <Dimmer active={ticketListLoading} inverted style={{ position: 'fixed', top: '0', left: '0' }} >
        <Icon name='spinner' loading size='huge' color='blue' />
      </Dimmer>
      <Grid.Row className='page-content' style={{ marginBottom: '0' }}>
        <Grid.Column width={3} className='filter-bar'>
          <Form.Button basic color='red' fluid onClick={clearFilters} style={{ borderRadius: '20px', marginTop: '30px' }}  >
            Clear Filters
          </Form.Button>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <h4>Show only my tickets</h4>
            <Form.Checkbox checked={filters.onlyMyTickets}
              onChange={toggleMyFilters} name='onlyMyTickets' toggle />
          </div>
          {user.role.type === 'authenticated' &&
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
              <h4>Show only assigned to me</h4>
              <Form.Checkbox checked={filters.assignedToMe}
                onChange={toggleAssignedToMe} name='assignedToMe' toggle />
            </div>
          }
          {user.role.type === 'authenticated' &&
            <Button.Group fluid vertical style={{ marginTop: 20, marginBottom: 20 }}>
              <h4>Customer</h4>
              {
                clientDD.map(c => (
                  <Button basic fluid key={c.key} onClick={() => toggleFilter('client', c.value)} active={filters.client.includes(c.value)} >
                    {c.text} ({c.code})
                  </Button>
                ))
              }
            </Button.Group>
          }
          <Button.Group fluid vertical style={{ marginTop: 20, marginBottom: 20 }}>
            <h4>Products</h4>

            {
              productDD.map(p => (
                <Button basic key={p.key} onClick={() => toggleFilter('product', p.value)} active={filters.product.includes(p.value)} >
                  {p.text}
                </Button>
              ))
            }
          </Button.Group>
          <Button.Group fluid vertical style={{ marginTop: 20, marginBottom: 20 }}>
            <h4>Ticket Type</h4>
            {
              ticketTypeDD.map(t => (
                <Button basic key={t.key} onClick={() => toggleFilter('type', t.value)} active={filters.type.includes(t.value)} >
                  {t.text}
                  {ticketIconMap[t.key]}
                </Button>
              ))
            }
          </Button.Group>
          <h4>Prioriy</h4>

          <Button.Group fluid vertical style={{ marginTop: 20, marginBottom: 20 }}>
            {
              priorityDD.map(p => (
                <Button basic key={p.key} onClick={() => toggleFilter('priority', p.value)} active={filters.priority.includes(p.value)} >
                  {p.text}
                </Button>
              ))
            }
          </Button.Group>
          <Button.Group fluid vertical style={{ marginTop: 20, marginBottom: 20 }}>
            <h4>Resolution</h4>

            {
              resolutionDD.map(r => (
                <Button basic key={r.key} onClick={() => toggleFilter('resolution', r.value)} active={filters.resolution.includes(r.value)} >
                  {r.text}
                </Button>
              ))
            }
          </Button.Group>
        </Grid.Column>
        <Grid.Column width={13} id='ticket-list-main-col'>

          {!isVisibleError ?
            <>
              <Statistic.Group size='tiny' widths='six' className='ticket-statistics'>
                {
                  statusValues.map(s => (
                    <Statistic key={s} className={`status-filter` + (filters.status.includes(s) ? ' selected' : '')} onClick={() => handleChangeStatus(s)}>
                      <Statistic.Value>{statusCounts[s]}</Statistic.Value>
                      <Statistic.Label>
                        {statusTextMap[s]}
                      </Statistic.Label>
                    </Statistic>
                  ))
                }
                <Statistic>
                  <Statistic.Value>{statusCounts['total']}</Statistic.Value>
                  <Statistic.Label>
                    Total
                  </Statistic.Label>
                </Statistic>
              </Statistic.Group>
              <TicketsTable user={user} columns={columns} data={transformData()} fetchData={fetchData} pageCount={pageCount}
                sortParams={sortParams} setSortParams={setSortParams} />
            </> :
            <TicketErrorMessage isVisible={isVisibleError} >
              Failed to load tickets
            </TicketErrorMessage>
          }
        </Grid.Column>
      </Grid.Row>
    </Grid >
  );
};

export default TicketListPage;