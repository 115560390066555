import React from 'react';
import { useTable } from 'react-table';
import { Table } from 'semantic-ui-react';



const AdminTicketStatsTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })


  return (
    <Table celled {...getTableProps()}>
      <Table.Header>
        {
          headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <Table.HeaderCell textAlign='center' {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          ))
        }
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {
          rows.map((row, i) => {
            prepareRow(row);
            
            return (
              <Table.Row {...row.getRowProps()}>
                {
                  row.cells.map(cell => {
                    return (
                      <Table.Cell textAlign='center' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })
                }
              </Table.Row>
            );
          })
        }
      </Table.Body>
    </Table>
  );
}

export default AdminTicketStatsTable;
