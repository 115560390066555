import React from 'react';
import { scrollToElement } from '../../../helpers';
import '../product.css';


const ArticleHeaderList = (props) => {
  if (props.headerList.length) {
    return (
      <ul className='article-menu-subheader-list' >
        {
          props.headerList.map(header => (
            <li key={header.textContent} onClick={() => scrollToElement(header)}
              className='article-menu-subheader' >
              {header.textContent}
            </li>
          ))
        }
      </ul>
    );
  } else {
    return null;
  }
};

export default ArticleHeaderList;