import React from 'react';
import axios from 'axios';
import { Card, Input, Form, TextArea, Button } from 'semantic-ui-react';
import '../product.css';



export default class ProductInputCard extends React.Component {
  state = {
    name: '',
    description: ''
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  addProduct = () => {
    const product = {
      name: this.state.name,
      description: this.state.description
    };

    return axios.post('/products', product)
      .then(res => {
        this.props.getProducts();
        this.props.onClose();
      })
  }


  editProduct = () => {
    const product = {
      name: this.state.name,
      description: this.state.description
    };

    return axios.put(`/products/${this.props.product.id}`, product)
      .then(res => {
        this.props.getProducts();
        this.props.onClose();
      })
      .catch(err => {
        console.log('editProduct failed', err);
      });
  }


  componentDidMount() {
    if (this.props.isEdit) {
      this.setState({
        name: this.props.product.name,
        description: this.props.product.description
      });
    }
  }


  render() {
    return (
      <Card fluid id='new-product-card' className='product-card'>
        <Card.Content>
          <Card.Description>
            <Input placeholder='Product Name' name='name'
              value={this.state.name} onChange={this.handleInputChange} />
            <Form>
              <TextArea id='product-description-textarea' placeholder='Product Description' name='description'
                value={this.state.description} onChange={this.handleInputChange} rows={5} />
            </Form>
            
            <Button onClick={this.props.isEdit ? this.editProduct : this.addProduct} disabled={!this.state.name}>
              Submit
            </Button>
            <Button onClick={this.props.onClose}>
              Cancel
            </Button>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}