import React from 'react';
import axios from 'axios';
import { Button, Form, Modal } from 'semantic-ui-react';



export default class CollectionInputModal extends React.Component {
  state = {
    isOpen: false,
    name: ''
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  openModal = () => {
    if (this.props.isEdit) {
      this.setState({
        isOpen: true,
        name: this.props.collection.name
      });
    } else {
      this.setState({
        isOpen: true,
        name: ''
      });
    }
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  }


  addCollection = () => {
    const len = this.props.collections.length;
    const order = len ? this.props.collections[len - 1].order + 1 : 0;

    const collection = {
      name: this.state.name,
      langversion: this.props.langVersionId,
      order: order
    };

    return axios.post('/collections', collection)
      .then(res => {
        this.props.getCollectionList(this.props.langVersionId);
        this.closeModal();
      })
  }


  editCollection = () => {
    const collection = {
      name: this.state.name
    };

    return axios.put(`/collections/${this.props.collection.id}`, collection)
      .then(res => {
        this.props.getCollectionList(this.props.langVersionId);
        this.closeModal();
      })
      .catch(err => {
        console.log('editCollection failed', err);
      });
  }



  render() {
    return (
      <Modal open={this.state.isOpen} onOpen={this.openModal} onClose={this.closeModal} size='tiny'
        trigger={this.props.trigger}>
        <Modal.Header>{this.props.isEdit ? 'Edit Collection' : 'New Collection'}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input label='Name' name='name' placeholder='Collection Name'
              value={this.state.name} onChange={this.handleInputChange} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.isEdit ? this.editCollection : this.addCollection} disabled={!this.state.name} >
            Submit
          </Button>
          <Button onClick={this.closeModal} >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}