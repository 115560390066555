import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Grid, Image, Input, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import netasLogo from '../../images/netaslogo.png';
import visiumLogo from '../../images/VisiumGoLogo.png';
import './login.css';


const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isVisibleError, setIsVisibleError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  const onSubmit = () => {
    setIsLoading(true);
    setIsDisabled(true);
    axios.post('/auth/local', {
      "identifier": username,
      "password": password
    }).then(res => {
      localStorage.setItem('user', JSON.stringify(res.data));
      window.location.reload();
    }).catch(err => {
      setIsVisibleError(false);
      setIsLoading(false);
      setIsDisabled(false);
      console.log(err)
    });
  };

  return (
    <div>
      <Grid id='login-grid' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Image id='visium-logo' src={visiumLogo} centered />
          <Form id='login-form' size='large' error>
            <div>
              <Form.Field>
                <Input size='big' fluid name="email" icon='user' iconPosition='left'
                  placeholder='Username' value={username}
                  onChange={event => {
                    setUsername(event.target.value)
                  }} />
              </Form.Field>

              <Form.Field>
                <Input fluid size='big' icon='lock' iconPosition='left'
                  name="password" type="password" placeholder='Password'
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value)
                  }} />
              </Form.Field>

              <Message error hidden={isVisibleError}>
                Login Failed
              </Message>

              <Button primary fluid size='huge' onClick={onSubmit} loading={isLoading} disabled={isDisabled}>
                Login
              </Button>
            </div>
          </Form>
          <Link to='/forgot-password'>Forgot Password</Link>
          <Image id='netas-logo' src={netasLogo} centered />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LoginPage;