import React from 'react';
import axios from 'axios';
import moment from 'moment';
import PageHeader from '../../layout/PageHeader';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment, Table, Icon, Dropdown, Image, Button } from 'semantic-ui-react';
import LogComment from './components/LogComment';
import RegularComment from './components/RegularComment';
import CommentInput from './components/CommentInput';
import ChangeStatusModal from './components/ChangeStatusModal';
import ChangePriorityModal from './components/ChangePriorityModal';
import AssignTicketModal from './components/AssignTicketModal';
import TicketErrorMessage from './components/TicketErrorMessage';
import {
  priorityBgColorMap, priorityFgColorMap, statusTextMap, resolutionIconMap, resolutionTextMap,
  imgExtensions
} from '../../constants';
import fileImg from '../../images/file.jpg';
import './ticket.css';

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);


const getTicketAge = (createdAt, closeDate) => {
  const odate = moment(createdAt);
  const cdate = moment(closeDate);

  return moment.duration(moment.duration(cdate.diff(odate)), "minutes").format('d [d], h [hr], m [min]');
}



export default class TicketDetailsPage extends React.Component {
  ticketId = this.props.match.params.id;
  userStr = localStorage.getItem("user");
  userRole = JSON.parse(this.userStr) && JSON.parse(this.userStr).user.role.type;
  user = JSON.parse(this.userStr) && JSON.parse(this.userStr).user;

  state = {
    ticket: {},
    comments: [],
    ticketLoading: false,
    commentsLoading: false,
    showTicketLoadError: false,
    showCommentLoadError: false,
    editCommentId: null
  };


  handleInputChange = (e, { name, value }) => this.setState({ [name]: value });


  getTicket = (id) => {
    this.setState({ ticketLoading: true });
    return axios.get(`/tickets/${id}`)
      .then(res => {
        this.setState({
          ticket: res.data,
          showTicketLoadError: false
        });
      })
      .catch(err => {
        console.log('getTicket failed', err);
        this.setState({ showTicketLoadError: true });
      })
      .finally(() => this.setState({ ticketLoading: false }));
  }


  getComments = (ticketId) => {
    this.setState({ commentsLoading: true });
    return axios.get(`/ticketcomments?ticket=${ticketId}`)
      .then(res => {
        res.data.sort((c1, c2) => c1.createdAt > c2.createdAt ? 1 : c1.createdAt < c2.createdAt ? -1 : 0);
        this.setState({
          comments: res.data,
          showCommentLoadError: false
        });
      })
      .catch(err => {
        console.log('getComments failed', err);
        this.setState({ showCommentLoadError: true });
      })
      .finally(() => this.setState({ commentsLoading: false }));
  }


  assignTicket = (userId) => {
    const updatedTicket = {
      assignedTo: userId,
      status: 'inProgress'
    };

    return axios.put(`/tickets/assign/${this.state.ticket.id}`, updatedTicket)
      .then(res => {
        this.setState({ ticket: res.data });
        this.getComments(res.data.id);
      })
      .catch(err => console.log('assignTicket failed', err));
  }


  openTicket = () => {
    return axios.put(`/tickets/open/${this.state.ticket.id}`)
      .then(res => {
        this.setState({ ticket: res.data });
        this.getComments(res.data.id);
      })
      .catch(err => console.log('openTicket failed', err));
  }


  updateTicket = (newTicket) => {
    this.setState({ ticket: newTicket });
  }


  setEditCommentId = (id) => {
    this.setState({ editCommentId: id });
  }


  scrollToElement = elem => {
    elem.scrollIntoView({
      behavior: 'smooth',
      block: "center"
    });
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.getTicket(this.ticketId);
    this.getComments(this.ticketId);
  }


  render() {
    const priorityStyle = { padding: '4px 8px', borderRadius: '4px' };
    priorityStyle.borderColor = priorityBgColorMap[this.state.ticket.priority];
    priorityStyle.color = priorityFgColorMap[this.state.ticket.priority];

    console.log();

    return (
      <Grid>
        <PageHeader title='Ticket Detail' description={this.state.ticket.title || 'No ticket information'} icon='sticky note outline'>
          <Button icon as={Link} to='/ticket-list' >
            <Icon name='arrow left' />
            Back to list
              </Button>
        </PageHeader>
        <Grid.Row className='page-content'>
          <Grid.Column id='view-ticket-main-col' >
            {!this.state.showTicketLoadError ?
              <Segment id='ticket-details-segment' loading={this.state.ticketLoading} >
                <div>
                  <div id='ticket-header'>
                    <Header>
                      {this.state.ticket.id &&
                        `[${this.state.ticket.ticketId}] ${this.state.ticket.title}`
                      }
                    </Header>
                    <Dropdown id='ticket-options-dd' compact item icon='ellipsis horizontal' direction='right' >
                      <Dropdown.Menu>
                        { // Assign ticket to someone
                          this.userRole === 'authenticated' &&
                          <AssignTicketModal updateTicket={this.updateTicket} getComments={this.getComments} ticket={this.state.ticket} />
                        }
                        { // Change ticket priority
                          this.userRole === 'authenticated' &&
                          <ChangePriorityModal updateTicket={this.updateTicket} getComments={this.getComments} ticket={this.state.ticket} />
                        }
                        { // Change ticket status
                          this.state.ticket.status !== 'closed' && this.userRole === 'authenticated' && this.state.ticket.assignedTo?.id === this.user.id &&
                          <ChangeStatusModal updateTicket={this.updateTicket} getComments={this.getComments} ticket={this.state.ticket} />
                        }
                        { // Open ticket
                          this.state.ticket.status === 'closed' && this.userRole === 'authenticated' &&
                          <Dropdown.Item onClick={this.openTicket} >
                            <Icon name='envelope open outline' />
                            Open Ticket
                            </Dropdown.Item>
                        }
                        { // Edit ticket
                          this.state.ticket.status !== 'closed' && this.state.ticket.openedBy?.id === this.user.id &&
                          <Dropdown.Item as={Link} to={`/edit-ticket/${this.state.ticket.id}`}>
                            <Icon name='edit outline' />
                            Edit Ticket
                          </Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div id='ticket-description' >
                    {this.state.ticket.description}
                  </div>
                </div>


                <div className='ticket-meta'>
                  <Table basic='very' celled style={{ padding: 10 }}>
                    <Table.Body>
                      <Table.Row >
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Ticket Age
                              <Header.Subheader>
                                {getTicketAge(this.state.ticket.createdAt, this.state.ticket.closeDate)}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Client
                              <Header.Subheader>
                                {this.state.ticket.id && this.state.ticket.client.name}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Opened By
                              <Header.Subheader>
                                {this.state.ticket.openedBy ? this.state.ticket.openedBy.firstName + ' ' + this.state.ticket.openedBy.lastName : ''}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>

                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Assigned To
                              <Header.Subheader>
                                {this.state.ticket.assignedTo ? this.state.ticket.assignedTo.firstName + ' ' + this.state.ticket.assignedTo.lastName : '-'}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Product
                              <Header.Subheader>
                                {this.state.ticket.product ? this.state.ticket.product.name : ''}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Version
                              <Header.Subheader>
                                {this.state.ticket.versionNo}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Type
                              <Header.Subheader>
                                {this.state.ticket.type}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Priority
                              <Header.Subheader>
                                {this.state.ticket.priority}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Status
                              <Header.Subheader>
                                {statusTextMap[this.state.ticket.status]}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Estimated Fix Version
                              <Header.Subheader>
                                {this.state.ticket.estimatedFixVer || '-'}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Estimated Fix Date
                              <Header.Subheader>
                                {this.state.ticket.estimatedFixDate ? moment(this.state.ticket.estimatedFixDate).format("DD.MMM.YYYY") : '-'}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' image>
                            <Header.Content>
                              Resolution
                              <Header.Subheader>
                                {this.state.ticket.status !== 'closed' ? '-' :
                                  <span>
                                    {resolutionIconMap[this.state.ticket.resolution]}
                                    {resolutionTextMap[this.state.ticket.resolution]}
                                  </span>
                                }
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>

                  </Table>
                </div>
                {
                  this.state.ticket.attachments && !!this.state.ticket.attachments.length &&
                  <Header.Content id='ticket-attachments-header'>
                    <h3>Attachments</h3>
                    <Header.Subheader id='ticket-attachments-subheader'>
                      <Image.Group size='small'>
                        {this.state.ticket.attachments.map(file => {
                          return (
                            imgExtensions.has(file.ext.toLowerCase()) ?
                              <a href={axios.defaults.baseURL + file.url} target="_blank" rel="noopener noreferrer" key={file.id} >
                                <Image bordered src={axios.defaults.baseURL + file.url} className="custom-image" />
                              </a> :
                              <a href={axios.defaults.baseURL + file.url} target="_blank" rel="noopener noreferrer" key={file.id} >
                                <Image bordered src={fileImg} className="custom-image" label={file.name} />
                              </a>
                          )
                        })}
                      </Image.Group>
                    </Header.Subheader>
                  </Header.Content>
                }
              </Segment> :
              <TicketErrorMessage isVisible={this.state.showTicketLoadError}>
                Failed to load ticket
              </TicketErrorMessage>
            }

            {this.state.showTicketLoadError ? null :
              this.state.showCommentLoadError ?
                <TicketErrorMessage isVisible={this.state.showCommentLoadError}>
                  Failed to load comments
              </TicketErrorMessage> :
                <Segment id='ticket-comments-segment' loading={this.state.commentsLoading} >
                  <Header style={{padding:20}}>Comments</Header>
                  {
                    this.state.comments.length &&
                    this.state.comments.map(comment => {
                      if (comment.type === 'comment') {
                        if (comment.id === this.state.editCommentId) {
                          return <CommentInput key={comment.id} ticket={this.state.ticket} getTicket={this.getTicket} getComments={this.getComments} userRole={this.userRole}
                            comment={comment} setEditCommentId={this.setEditCommentId} isEdit={true} />
                        } else {
                          return <RegularComment key={comment.id} comment={comment} user={this.user} setEditCommentId={this.setEditCommentId} />;
                        }
                      }
                      else if (comment.type === 'log') {
                        return <LogComment key={comment.id} comment={comment} ticket={this.state.ticket} />;
                      }
                      else {
                        return null;
                      }
                    })
                  }

                  <CommentInput ticket={this.state.ticket} getTicket={this.getTicket} getComments={this.getComments} userRole={this.userRole} />
                </Segment>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};
