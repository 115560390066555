import React from 'react';
import axios from 'axios';
import { Dimmer, Dropdown, Grid, Icon, Message, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PageHeader from '../../layout/PageHeader';
import LanguageDD from './components/LanguageDD';
import CollectionList from './components/CollectionList';
import ArticleViewer from './components/ArticleViewer';
import { scrollToElement } from '../../helpers';
import './product.css';



export default class ProductDocsPage extends React.Component {
  productId = this.props.match.params.productId;

  state = {
    product: {},
    version: {},
    langVersion: {},
    lang: sessionStorage.getItem('lang') || 'en',
    versionNo: sessionStorage.getItem(`${this.productId} versionNo`) || null,
    collections: [],
    articleId: this.props.match.params.articleId === undefined ? null : this.props.match.params.articleId,
    headerList: [],
    articleLoading: false
  };


  toggleArticleLoading = (val) => {
    this.setState({ articleLoading: val });
  }


  selectArticle = (id) => {
    this.setState({ articleId: id });
  }


  getHeaders = () => {
    const headerList = document.querySelectorAll('#article-viewer h2');
    this.setState({
      headerList: Array.from(headerList)
    }, () => {
      const mainHeader = document.getElementById('article-title-header');
      scrollToElement(mainHeader, 'auto');
    });
  }


  getCollectionList = (langversionId) => {
    return axios.get(`/collections?langversion=${langversionId}&published=${true}`)
      .then(res => {
        // sort the collections
        res.data.sort((c1, c2) => c1.order < c2.order ? -1 : 1);
        // sort the articles inside the collections
        res.data.forEach(col => {
          col.articles.sort((a1, a2) => a1.order < a2.order ? -1 : 1);
        });
        this.setState({ collections: res.data });
      });
  }

  // isChange is true when changing version or language
  getVersion = (id, isChange = false) => {
    return axios.get(`/versions/${id}`)
      .then(res => {
        const langVersion = res.data.langversions.find(v => v.code === this.state.lang);

        this.setState({
          version: res.data,
          versionNo: res.data.versionNo,
          langVersion: langVersion
        });

        this.getCollectionList(langVersion.id)
          .then(() => {
            if (isChange || this.state.articleId === null) {
              // find the first collection that contains an article
              const tempCol = this.state.collections.find(c => c.articles.length);

              if (tempCol) {
                this.selectArticle(tempCol.articles[0].id);
              } else {
                this.selectArticle(null);
              }
            } else {
              this.selectArticle(this.state.articleId);
            }
          });
      })
  }


  getProduct = (id, ver = null) => {
    return axios.get(`/products/${id}`)
      .then(res => {
        this.setState({ product: res.data });

        let lastVersion;
        if (this.state.versionNo !== null) {
          lastVersion = res.data.versions.find(v => v.versionNo === this.state.versionNo);
        } else {
          lastVersion = res.data.versions[0];
          res.data.versions.forEach(v => {
            if (v.versionNo > lastVersion.versionNo) {
              lastVersion = v;
            }
          });
        }

        if (lastVersion) {
          sessionStorage.setItem(`${res.data.id} versionNo`, lastVersion.versionNo);
          this.getVersion(lastVersion.id);
        }
      });
  }


  changeVersion = (e, { name, value }) => {
    let selectedVersion = this.state.product.versions.find(v => v.id === value);
    this.getVersion(selectedVersion.id, true);
    sessionStorage.setItem(`${this.state.product.id} versionNo`, selectedVersion.versionNo);

  }


  changeLanguage = (e, { name, value }) => {
    this.setState({ lang: value }, () => {
      sessionStorage.setItem('lang', value);
      this.getVersion(this.state.version.id, true);
    });
  }


  componentDidMount() {
    this.getProduct(this.productId);
    sessionStorage.setItem('lang', this.state.lang);
  }


  render() {
    let versionsDD = [];
    if (this.state.product.versions) {
      versionsDD = this.state.product.versions.map(v => (
        { key: v.id, text: v.versionNo, value: v.id }
      ));
    }

    return (
      <Grid>
        <PageHeader title={this.state.product.name} description={`Collections and articles for ${this.state.product.name}`} icon='list alternate outline'>
          {this.state.product.versions?.length ?
            <>
              <Dropdown selection compact placeholder='Select Version' options={versionsDD} name="version"
                value={this.state.version.id} onChange={this.changeVersion} />
              <LanguageDD lang={this.state.lang} changeLanguage={this.changeLanguage} />
            </> :
            <Button icon as={Link} to='/product-list' >
              <Icon name='arrow left' />
              Back to list
            </Button>
          }
        </PageHeader>

        <Grid.Row className='page-content' columns={2}>
          {this.state.product.versions?.length ?
            <>
              <Grid.Column width={4}>
                <CollectionList collections={this.state.collections} articleId={this.state.articleId} selectArticle={this.selectArticle}
                  headerList={this.state.headerList} productId={this.state.product.id} />
              </Grid.Column>

              <Grid.Column width={12}>
                <Dimmer active={this.state.articleLoading} inverted >
                  <Icon name='spinner' loading size='huge' color='blue' />
                </Dimmer>
                {this.state.articleId ?
                  <ArticleViewer articleId={this.state.articleId} getHeaders={this.getHeaders}
                    toggleArticleLoading={this.toggleArticleLoading} /> :
                  <Message>
                    <Message.Header>No Article</Message.Header>
                    <p>There is no documentation to display.</p>
                  </Message>
                }
              </Grid.Column>
            </> :
            <Message style={{ margin: '50px auto' }}>
              <Message.Header>No Documentation</Message.Header>
              <p>The documentation for this product is not ready yet.</p>
            </Message>
          }
        </Grid.Row>
      </Grid>
    );
  }
}