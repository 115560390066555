import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Confirm } from 'semantic-ui-react';
import ProductOptionsDD from './ProductOptionsDD';
import ProductInputCard from './ProductInputCard';
import '../product.css';



export default class ProductList extends React.Component {
  state = {
    hoverProductId: null,
    editProductId: null,
    deleteProductId: null,
    isVisibleDeleteProduct: false
  };


  setEditProductId = (id) => {
    this.setState({ editProductId: id });
  }


  openDeleteProductModal = (id) => {
    this.setState({
      deleteProductId: id,
      isVisibleDeleteProduct: true
    });
  }


  closeDeleteProductModal = () => {
    this.setState({
      deleteProductId: null,
      isVisibleDeleteProduct: false
    });
  }


  deleteProduct = () => {
    return axios.delete(`/products/${this.state.deleteProductId}`)
      .then(res => {
        this.props.getProducts();
        this.closeDeleteProductModal();
      })
  }


  render() {
    return (
      <>
        {
          this.props.products.map(product => {
            if (product.id !== this.state.editProductId) {
              return (
                <Card fluid className='product-card' key={product.id} onMouseEnter={() => this.setState({ hoverProductId: product.id })}
                  onMouseLeave={() => this.setState({ hoverProductId: null })} >
                  <Card.Content>
                    <Card.Header className='product-header'>
                      <Link to={this.props.userRole.type === 'authenticated' ? `/edit-product-docs/${product.id}` : `/product-docs/${product.id}`}
                        className='product-header-link'>
                        {product.name}
                      </Link>
                      {
                        this.props.userRole.type === 'authenticated' && this.state.hoverProductId === product.id &&
                        <ProductOptionsDD product={product} setEditProductId={this.setEditProductId} openDeleteProductModal={this.openDeleteProductModal} />
                      }
                    </Card.Header>
                    <Card.Meta>
                      {moment(product.created_at).format("DD.MMM.YYYY")}
                    </Card.Meta>
                    <Card.Description>
                      {product.description}
                    </Card.Description>
                  </Card.Content>
                </Card>
              )
            } else {
              return <ProductInputCard getProducts={this.props.getProducts} onClose={() => this.setEditProductId(null)}
                isEdit={true} product={product} />;
            }
          }
          )
        }

        <Confirm
          open={this.state.isVisibleDeleteProduct} size='tiny'
          header='Delete Product' content='Are you sure you want to delete this product and all its related documentation?'
          confirmButton="Delete" onConfirm={this.deleteProduct}
          onCancel={this.closeDeleteProductModal} />
      </>
    );
  }
}
