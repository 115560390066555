import React from 'react';
import { Message } from 'semantic-ui-react';
import '../ticket.css';


const TicketErrorMessage = (props) => {
  return (
    <Message id='ticket-load-error-message' error size='huge' visible={props.isVisible}>
      {props.children}
    </Message>
  );
}

export default TicketErrorMessage;
