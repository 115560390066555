import React from 'react';
import axios from 'axios';
import { Confirm, Dimmer, Dropdown, Grid, Icon, Message, Popup } from 'semantic-ui-react';
import PageHeader from '../../layout/PageHeader';
import LanguageDD from './components/LanguageDD';
import CollectionListEditor from './components/CollectionListEditor';
import ArticleEditor from './components/ArticleEditor';
import VersionInputModal from './components/VersionInputModal';
import './product.css';



const VersionStatusPopup = ({ published }) => {
  if (published) {
    return (
      <Popup
        content='The documentation for this version is published. All users can view it. To make it only visible to admins, change it to a draft.'
        header='Published'
        trigger={<Icon name='checkmark' color='green' size='large'
        />}
      />
    );
  } else {
    return (
      <Popup
        content='The documentation for this version is in draft form. Only admins can view it. Publish it to make it visible to all users.'
        header='Draft'
        trigger={<Icon name='firstdraft' color='yellow' size='large'
        />}
      />
    );
  }
}



export default class EditProductDocsPage extends React.Component {
  productId = this.props.match.params.productId;

  state = {
    product: {},
    version: {},
    langVersion: {},
    lang: sessionStorage.getItem('lang') || 'en',
    versionNo: sessionStorage.getItem(`${this.productId} versionNo`) || null,
    collections: [],
    articleId: this.props.match.params.articleId === undefined ? null : this.props.match.params.articleId,
    headerList: [],
    articleLoading: false,
    isVisibleDeleteVer: false
  };


  toggleArticleLoading = (val) => {
    this.setState({ articleLoading: val });
  }


  selectArticle = (id) => {
    this.setState({ articleId: id });
  }


  getCollectionList = (langversionId) => {
    return axios.get(`/collections?langversion=${langversionId}`)
      .then(res => {
        // sort the collections
        res.data.sort((c1, c2) => c1.order < c2.order ? -1 : 1);
        // sort the articles inside the collections
        res.data.forEach(col => {
          col.articles.sort((a1, a2) => a1.order < a2.order ? -1 : 1);
        });
        this.setState({ collections: res.data });
      });
  }


  // isChange is true when changing version or language
  getVersion = (id, isChange = false) => {
    return axios.get(`/versions/${id}`)
      .then(res => {
        const langVersion = res.data.langversions.find(v => v.code === this.state.lang);

        this.setState({
          version: res.data,
          versionNo: res.data.versionNo,
          langVersion: langVersion
        });

        this.getCollectionList(langVersion.id)
          .then(() => {
            if (isChange || this.state.articleId === null) {
              // find the first collection that contains an article
              const tempCol = this.state.collections.find(c => c.articles.length);

              if (tempCol) {
                this.selectArticle(tempCol.articles[0].id);
              } else {
                this.selectArticle(null);
              }
            } else {
              this.selectArticle(this.state.articleId);
            }
          });
      })
  }


  getProduct = (id, ver = null) => {
    return axios.get(`/products/${id}`)
      .then(res => {
        this.setState({ product: res.data });

        let lastVersion;

        if (ver) {
          lastVersion = ver;
        } else if (this.state.versionNo !== null) {
          lastVersion = res.data.versions.find(v => v.versionNo === this.state.versionNo);
        } else {
          lastVersion = res.data.versions[0];
          res.data.versions.forEach(v => {
            if (v.versionNo > lastVersion.versionNo) {
              lastVersion = v;
            }
          });
        }
        sessionStorage.setItem(`${res.data.id} versionNo`, lastVersion.versionNo);

        this.getVersion(lastVersion.id);
      });
  }


  openDeleteVerModal = () => {
    this.setState({ isVisibleDeleteVer: true });
  }


  closeDeleteVerModal = () => {
    this.setState({ isVisibleDeleteVer: false });
  }


  deleteVersion = () => {
    return axios.delete(`/versions/${this.state.version.id}`)
      .then(res => {
        this.setState({ versionNo: null });
        sessionStorage.removeItem(`${this.state.product.id} versionNo`);
        this.getProduct(this.state.product.id);
        this.closeDeleteVerModal();
      });
  }


  addArticle = (collection) => {
    //Otomatik olarak yeni bir article i collection altinda yaratacak. Onu secili hale getirecek ve editorde gosterece
    const len = collection.articles.length;
    const order = len ? collection.articles[len - 1].order + 1 : 0;
    const user = JSON.parse(localStorage.getItem('user'));

    const article = {
      title: 'Untitled',
      content: '{}',
      col: collection.id,
      order: order,
      published: false,
      createdBy: user.user.id,
      updatedBy: user.user.id
    };

    return axios.post('/articles', article)
      .then(res => {
        this.getCollectionList(this.state.langVersion.id);
        this.setState({ articleId: res.data.id });
        console.log('success');
      })
      .catch(err => {
        console.log('addArticle failed', err);
      });
  }


  deleteArticle = (articleId) => {
    return axios.delete(`/articles/${articleId}`)
      .then(res => {
        this.getCollectionList(this.state.langVersion.id)
          .then(() => {
            const firstCol = this.state.collections.find(c => c.articles.length > 0);
            const firstArt = firstCol ? firstCol.articles[0] : null;
            this.selectArticle(firstArt ? firstArt.id : null);
          })
      })
      .catch(err => {
        console.log('deleteArticle failed', err);
      })
      .finally(() => this.closeDeleteArtModal());
  }


  updateCollections = (newCollections) => {
    this.setState({ collections: newCollections });
  }


  changeVersion = (e, { name, value }) => {
    let selectedVersion = this.state.product.versions.find(v => v.id === value);
    this.getVersion(selectedVersion.id, true);
    sessionStorage.setItem(`${this.state.product.id} versionNo`, selectedVersion.versionNo);
  }


  changeLanguage = (e, { name, value }) => {
    this.setState({ lang: value }, () => {
      sessionStorage.setItem('lang', value);
      this.getVersion(this.state.version.id, true);
    });
  }

  
  toggleVersionStatus = async () => {
    try {
      const res = await axios.put(`/versions/${this.state.version.id}`, { published: !this.state.version.published });
      this.setState({ version: res.data });
    } catch (err) {
      console.log('toggleVersionStatus failed', err);
    }
  }


  componentDidMount() {
    this.getProduct(this.productId);
    sessionStorage.setItem('lang', this.state.lang);
  }


  render() {
    let versionsDD = [];
    if (this.state.product.versions) {
      versionsDD = this.state.product.versions.map(v => (
        { key: v.id, text: v.versionNo, value: v.id }
      ));
    }

    return (
      <>
        <Grid>
          <PageHeader title={this.state.product.name} description={`Edit the documentation for ${this.state.product.name}`} icon='list alternate outline'>
            <VersionStatusPopup published={this.state.version.published} />
            <Dropdown selection compact placeholder='Select Version' options={versionsDD} name="version"
              value={this.state.version.id} onChange={this.changeVersion} />
            <Dropdown id='version-options-dd' compact item icon='ellipsis horizontal' direction='left' >
              <Dropdown.Menu>
                <VersionInputModal productId={this.state.product.id} getProduct={this.getProduct}
                  versions={this.state.product.versions} lang={this.state.lang} />
                <Dropdown.Item onClick={this.openDeleteVerModal}>Delete current version</Dropdown.Item>
                <VersionInputModal isEdit={true} version={this.state.version} getProduct={this.getProduct} productId={this.state.product.id} />
                <Dropdown.Item onClick={this.toggleVersionStatus}>
                  {this.state.version.published ? 'Draft current version' : 'Publish current version'}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <LanguageDD lang={this.state.lang} changeLanguage={this.changeLanguage} />
          </PageHeader>

          <Grid.Row id='doc-editor-page' className='page-content' columns={2}>
            <Grid.Column width={4}>
              <CollectionListEditor collections={this.state.collections} getCollectionList={this.getCollectionList} langVersionId={this.state.langVersion.id}
                addArticle={this.addArticle} updateCollections={this.updateCollections} productId={this.state.product.id} selectArticle={this.selectArticle}
                articleId={this.state.articleId} />
            </Grid.Column>

            <Grid.Column width={12}>
              <Dimmer active={this.state.articleLoading} inverted >
                <Icon name='spinner' loading size='huge' color='blue' />
              </Dimmer>
              {this.state.articleId ?
                <ArticleEditor articleId={this.state.articleId} langVersionId={this.state.langVersion.id} getCollectionList={this.getCollectionList}
                  toggleArticleLoading={this.toggleArticleLoading} /> :
                <Message>
                  <Message.Header>No Article</Message.Header>
                  <p>Create an article to display its content here.</p>
                </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Confirm
          open={this.state.isVisibleDeleteVer} size='tiny'
          header='Delete Version' content='Are you sure you want to delete this version?'
          confirmButton="Delete" onConfirm={this.deleteVersion}
          onCancel={this.closeDeleteVerModal} />
      </>
    );
  }
}