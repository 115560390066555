import React from 'react';
import { Grid, Header, Card, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PageHeader from './admin/layout/PageHeader';

export default class MainPage extends React.Component {
  userStr = localStorage.getItem("user");
  userRole = JSON.parse(this.userStr) && JSON.parse(this.userStr).user.role.type;

  render() {
    return (
      <>
        <Grid >
          <PageHeader title={this.props.isEdit ? 'Edit Ticket' : 'New Ticket'} description="Please fill the form " icon='sticky note outline'>
            <Button color='red' icon as={Link} to='/login' onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location = '/login';
            }} >
              <Icon name='power' />
                            Logout
                     </Button>
          </PageHeader>
          <Grid.Row style={{ justifyContent: 'center' }}>
            <Grid.Column style={{ maxWidth: '550px', marginTop: '200px' }} verticalAlign='middle' >
              <div>
                <Header as='h2' textAlign='center'>
                  Welcome to Visium Desk !
                            </Header>
                <div>
                  <Card className='main-menu-item' fluid as={Link} to={`/ticket-list/`}>
                    <Header as='h3'>
                      <Icon name='unordered list' />
                      <Header.Content style={{ paddingLeft: 20 }}>
                        Ticket List
                                            </Header.Content>
                    </Header>

                  </Card>
                  <Card className='main-menu-item' fluid as={Link} to={`/new-ticket/`}>
                    <Header as='h3'>
                      <Icon name='bug' />
                      <Header.Content style={{ paddingLeft: 20 }}>
                        Create New Ticket
                                            </Header.Content>
                    </Header>

                  </Card>
                  <Card className='main-menu-item' fluid as={Link} to={`/product-list/`}>
                    <Header as='h3'>
                      <Icon name='file alternate outline' />
                      <Header.Content style={{ paddingLeft: 20 }}>
                        Documentation
                                            </Header.Content>
                    </Header>

                  </Card>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}