import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Grid, Image, Input, Message } from 'semantic-ui-react';
import netasLogo from '../../images/netaslogo.png';
import visiumLogo from '../../images/VisiumGoLogo.png';
import './login.css';


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isVisibleError, setIsVisibleError] = useState(true);
  const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  const onSubmit = () => {
    setIsLoading(true);
    setIsDisabled(true);
    setIsVisibleError(true);
    axios.post('/auth/forgot-password', {
      "email": email
    }).then(res => {
      setIsVisibleSuccess(true);
      setIsLoading(false);
    }).catch(err => {
      setIsVisibleError(false);
      setIsLoading(false);
      setIsDisabled(false);
      console.log(err)
    });
  };

  return (
    <div>
      <Grid id='login-grid' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Image id='visium-logo' src={visiumLogo} centered />
          <Form id='login-form' size='large' error>
            <div>
              <Form.Field>
                <Input required type='email' size='big' fluid name="email" icon='user' iconPosition='left'
                  placeholder='Email' value={email}
                  onChange={event => {
                    setEmail(event.target.value)
                  }} />
              </Form.Field>

              <Message error hidden={isVisibleError}>
                Password Reset Failed
              </Message>

              <Message success visible={isVisibleSuccess}>
                A reset password link was sent to your email
              </Message>

              <Button primary fluid size='huge' onClick={onSubmit} loading={isLoading} disabled={isDisabled}>
                Submit
              </Button>
            </div>
          </Form>
          
          <Image id='netas-logo' src={netasLogo} centered />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgotPasswordPage;