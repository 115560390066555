import React, { useMemo, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import { Table, Pagination, Icon, Dropdown } from 'semantic-ui-react';
import '../ticket.css';



const TicketsTable = ({ columns, data, fetchData, user, pageCount: controlledPageCount, sortParams, setSortParams }) => {
  const colWidthMap = useMemo(() => ({
    ticketId: 2,
    title: 4,
    openedBy: 2,
    assignedTo: 2,
    product: 2,
    age: 1,
    priority: 1,
    status: 2
  }), []);


  const pageSizeDD = useMemo(() => (
    [10, 25, 50, 100].map(pageSize => ({ key: pageSize, text: `Show ${pageSize}`, value: pageSize }))
  ), []);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: parseInt(sessionStorage.getItem('pageIndex')) || 0,
        pageSize: parseInt(sessionStorage.getItem('pageSize')) || 10
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );


  const changeSorting = (col) => {
    if (col.id !== sortParams.field) {
      setSortParams({ field: col.id, direction: 'DESC' });
    } else if (sortParams.direction === 'DESC') {
      setSortParams({ field: col.id, direction: 'ASC' });
    } else {
      setSortParams({ field: 'createdAt', direction: 'DESC' });
    }
  }


  useEffect(() => {
    if ((pageCount > 0) && (pageIndex >= pageCount)) {
      gotoPage(0);
    }
  }, [pageCount]);


  useEffect(() => {
    fetchData({ pageIndex, pageSize })
    sessionStorage.setItem('pageIndex', pageIndex);
    sessionStorage.setItem('pageSize', pageSize);
  }, [fetchData, pageIndex, pageSize])


  return (
    <>
      <Table  selectable  {...getTableProps()} className='ticket-table'>
        <Table.Header >
          {
            headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => {
                    return (
                      <Table.HeaderCell width={colWidthMap[column.id]} {...column.getHeaderProps()}
                        onClick={() => changeSorting(column)}
                        className='sortableCol'>
                        {column.render('Header')}
                        <span>
                          {column.id === sortParams.field
                            ? sortParams.direction === 'DESC'
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </Table.HeaderCell>
                    )
                  })
                }
              </Table.Row>
            ))
          }
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {
            page.map((row, i) => {
              prepareRow(row);
              return (
                <Table.Row  {...row.getRowProps()}>
                  {
                    row.cells.map(cell => {
                      return (
                        <Table.Cell  {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      );
                    })
                  }
                </Table.Row>
              );
            })
          }
        </Table.Body>
      </Table>

      <Pagination
        totalPages={pageOptions.length}
        activePage={pageIndex + 1}
        firstItem={{ content: <Icon name='angle double left' />, icon: true, onClick: () => gotoPage(0), disabled: !canPreviousPage }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true, onClick: () => gotoPage(pageCount - 1), disabled: !canNextPage }}
        prevItem={{ content: <Icon name='angle left' />, icon: true, onClick: () => previousPage(), disabled: !canPreviousPage }}
        nextItem={{ content: <Icon name='angle right' />, icon: true, onClick: () => nextPage(), disabled: !canNextPage }}
        onPageChange={(e, { activePage }) => gotoPage(activePage - 1)}
      />

      <Dropdown
        placeholder='Page Size'
        compact
        selection
        value={pageSize}
        options={pageSizeDD}
        onChange={(e, { name, value }) => setPageSize(value)}
        style={{ marginLeft: '20px' }}
      />
    </>
  );
}

export default TicketsTable;
