import React from 'react';
import axios from 'axios';
import { Grid, Dimmer, Icon, Card, Message, Button } from 'semantic-ui-react';
import PageHeader from '../../layout/PageHeader';
import ProductList from './components/ProductList';
import ProductInputCard from './components/ProductInputCard';
import { scrollToElement } from '../../helpers';
import './product.css';



export default class ProductListPage extends React.Component {
  user = JSON.parse(localStorage.getItem("user")).user;

  state = {
    products: [],
    productsLoading: false,
    isVisibleAddProduct: false
  };


  getProducts = () => {
    this.setState({ productsLoading: true });

    return axios.get('/products')
      .then(res => this.setState({ products: res.data }))
      .catch(err => console.log('getProducts failed\n', err))
      .finally(() => this.setState({ productsLoading: false }));
  }


  openAddProductCard = () => {
    this.setState({ isVisibleAddProduct: true }, () => {
      const card = document.getElementById('new-product-card');
      scrollToElement(card);
    });
  }


  closeAddProductCard = () => {
    this.setState({ isVisibleAddProduct: false });
  }


  componentDidMount() {
    this.getProducts();
  }


  render() {
    return (
      <Grid>
        <PageHeader title='Products' description="All products" icon='list alternate outline'>
          {
            this.user.role.type === 'authenticated' &&
            <Button icon primary onClick={this.openAddProductCard}>
              <Icon name='plus' />
              Add New Product
            </Button>
          }
        </PageHeader>

        <Grid.Row className='page-content'>
          <Grid.Column>
            <Dimmer active={this.state.productsLoading} inverted >
              <Icon name='spinner' loading size='huge' color='blue' />
            </Dimmer>
            <Card.Group centered>
              <ProductList products={this.state.products} userRole={this.user.role} getProducts={this.getProducts} />

              {
                this.state.isVisibleAddProduct &&
                <ProductInputCard getProducts={this.getProducts} onClose={this.closeAddProductCard} />
              }

              {
                !(this.state.products.length || this.state.productsLoading || this.state.isVisibleAddProduct) &&
                <Message className='no-product-message'>
                  <p>There are no products.</p>
                </Message>
              }
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}