import React from 'react';
import { Grid, Image } from "semantic-ui-react";
import './layout.css';
import { Link } from 'react-router-dom';


const PageHeader = (props) => {
  return (
    <Grid.Row columns={1} id='page-header'>
      <Grid.Column>
        <div>
        <Image src='/visiumDesk.png' as={Link} to='/' size='small' />
        </div>
        <div className="page-actions">
          {props.children}
        </div>
      </Grid.Column>
    </Grid.Row>
  )
}

export default PageHeader;