import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CollectionInputModal from './CollectionInputModal';
import '../product.css';



export default class CollectionListEditor extends React.Component {
  state = {
    collection: {},
    hoverColId: null,
    hoverArtId: null,
    isVisibleDeleteCol: false,
    isVisibleDeleteArt: false
  }


  openDeleteColModal = (collection) => {
    this.setState({
      collection: collection,
      isVisibleDeleteCol: true
    });
  }


  closeDeleteColModal = () => {
    this.setState({ isVisibleDeleteCol: false });
  }


  deleteCollection = () => {
    return axios.delete(`/collections/${this.state.collection.id}`)
      .then(res => {
        this.props.getCollectionList(this.props.langVersionId);
        this.closeDeleteColModal();
      });
  }


  openDeleteArtModal = (article) => {
    this.setState({
      article: article,
      isVisibleDeleteArt: true
    });
  }


  closeDeleteArtModal = () => {
    this.setState({ isVisibleDeleteArt: false });
  }


  deleteArticle = () => {
    return axios.delete(`/articles/${this.state.article.id}`)
      .then(res => {
        this.props.getCollectionList(this.props.langVersionId)
          .then(() => {
            const firstCol = this.props.collections.find(c => c.articles.length > 0);
            const firstArt = firstCol ? firstCol.articles[0] : null;
            this.props.selectArticle(firstArt ? firstArt.id : null);
          })
      })
      .catch(err => {
        console.log('deleteArticle failed', err);
      })
      .finally(() => this.closeDeleteArtModal());
  }


  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) { return; } // invalid drop

    if (result.type === 'article') {
      if (destination.droppableId === source.droppableId) {
        if (destination.index === source.index) { // position did not change
          return;
        } else { // same collection, different position
          const tempColList = [...this.props.collections];
          const colIdx = tempColList.findIndex(c => c.id === source.droppableId);

          const tempCol = { ...tempColList[colIdx] };
          const tempArticles = tempCol.articles.map(art => { return { ...art }; });

          const tempArt = tempArticles.splice(source.index, 1);
          tempArticles.splice(destination.index, 0, tempArt[0]);
          tempArticles.forEach((art, index) => art.order = index);

          tempCol.articles = tempArticles;
          tempColList[colIdx] = tempCol;
          this.props.updateCollections(tempColList);

          Promise.all(tempArticles.map(art => axios.put(`/articles/${art.id}`, { order: art.order })))
            .then(res => this.props.getCollectionList(this.props.langVersionId));
        }
      } else { // different collection
        const tempColList = [...this.props.collections];
        const sourceColIdx = tempColList.findIndex(c => c.id === source.droppableId);
        const destColIdx = tempColList.findIndex(c => c.id === destination.droppableId);
        const tempSourceCol = { ...tempColList[sourceColIdx] };
        const tempDestCol = { ...tempColList[destColIdx] };
        const tempSourceArticles = tempSourceCol.articles.map(art => { return { ...art }; });
        const tempDestArticles = tempDestCol.articles.map(art => { return { ...art }; });

        const tempArt = tempSourceArticles.splice(source.index, 1);
        tempDestArticles.splice(destination.index, 0, tempArt[0]);
        tempSourceArticles.forEach((art, index) => art.order = index);
        tempDestArticles.forEach((art, index) => art.order = index);

        tempSourceCol.articles = tempSourceArticles;
        tempDestCol.articles = tempDestArticles;
        tempColList[sourceColIdx] = tempSourceCol;
        tempColList[destColIdx] = tempDestCol;
        this.props.updateCollections(tempColList);

        Promise.all(tempSourceArticles.map(art => axios.put(`/articles/${art.id}`, { order: art.order })))
          .then(res => Promise.all(tempDestArticles.map(art => axios.put(`/articles/${art.id}`, { order: art.order, col: tempDestCol.id }))))
          .then(res => this.props.getCollectionList(this.state.langVersionId));
      }
    } else if (result.type === 'collection') {
      if (destination.index === source.index) { // position did not change
        return;
      } else {
        const tempColList = [...this.props.collections];
        const tempCol = tempColList.splice(source.index, 1);
        tempColList.splice(destination.index, 0, tempCol[0]);
        tempColList.forEach((col, index) => col.order = index);

        this.props.updateCollections(tempColList);

        Promise.all(tempColList.map(col => axios.put(`/collections/${col.id}`, { order: col.order })))
          .then(res => this.props.getCollectionList(this.state.langVersionId));
      }
    } else {
      return;
    }
  }


  render() {
    return (
      <div id='collection-list'>
        <CollectionInputModal langVersionId={this.props.langVersionId} getCollectionList={this.props.getCollectionList}
          collections={this.props.collections}
          trigger={
            <Button id='add-collection-button' icon primary fluid>
              <Icon name='plus' />
              Add New Collection
            </Button>} />

        <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId='col-droppable' type='collection'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {
                  this.props.collections.map((col, idx) => (
                    <Draggable draggableId={col.id} index={idx} key={col.id} >
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <Droppable droppableId={col.id} key={col.id} type='article' >
                            {(provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef} >
                                <div className='collection-menu-item' onMouseEnter={() => this.setState({ hoverColId: col.id })}
                                  onMouseLeave={() => this.setState({ hoverColId: null })} >
                                  <div>
                                    <h3>{col.name}</h3>
                                  </div>
                                  {this.state.hoverColId === col.id &&
                                    <div>
                                      <CollectionInputModal isEdit={true} collection={col} langVersionId={this.props.langVersionId} getCollectionList={this.props.getCollectionList}
                                        trigger={<Icon name='edit' title='Edit collection' />} />
                                      <Icon name='remove' onClick={() => this.openDeleteColModal(col)} title='Delete collection' />
                                      <Icon name='plus' onClick={() => this.props.addArticle(col)} title='Add article' />
                                    </div>
                                  }
                                </div>
                                {
                                  col.articles.map((art, idx) => (
                                    <Draggable draggableId={art.id} index={idx} key={art.id} >
                                      {(provided) => (
                                        <div {...provided.draggableProps} {...provided.dragHandleProps}
                                          onMouseEnter={() => this.setState({ hoverArtId: art.id })} onMouseLeave={() => this.setState({ hoverArtId: null })}
                                          className={'article-menu-item ' + (this.props.articleId === art.id && "article-menu-item-active")}
                                          ref={provided.innerRef}
                                        >
                                          <span id='article-title-span'>
                                            <Link className='article-title-link' to={`/edit-product-docs/${this.props.productId}/${art.id}`}
                                              onClick={() => this.props.selectArticle(art.id)} >
                                              {art.title}
                                            </Link>
                                            {art.published
                                              ? <Icon name='checkmark' color='green' className='article-status-icon' title='published' />
                                              : <Icon name='firstdraft' color='yellow' className='article-status-icon' title='draft' />
                                            }
                                          </span>

                                          {this.state.hoverArtId === art.id &&
                                            <Icon name='remove' onClick={() => this.openDeleteArtModal(art)} title='Delete article' id='remove-article-icon' />
                                          }
                                        </div>
                                      )}
                                    </Draggable>
                                  ))
                                }
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )
                      }
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )
            }
          </Droppable>
        </DragDropContext>

        <Confirm
          open={this.state.isVisibleDeleteCol} size='tiny'
          header='Delete Collection' content='Are you sure you want to delete this collection?'
          confirmButton="Delete" onConfirm={this.deleteCollection}
          onCancel={this.closeDeleteColModal} />

        <Confirm
          open={this.state.isVisibleDeleteArt} size='tiny'
          header='Delete Article' content='Are you sure you want to delete this article?'
          confirmButton="Delete" onConfirm={this.deleteArticle}
          onCancel={this.closeDeleteArtModal} />
      </div>
    );
  }
}
