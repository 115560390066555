import React from 'react';
import axios from 'axios';
import { Icon, Dropdown, Input, Radio, Message } from 'semantic-ui-react';
import moment from 'moment';
import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import CodeTool from '@editorjs/code';
// import Table from '@editorjs/table';
import '../product.css';



const resetTimeout = (id, newID) => {
  clearTimeout(id);
  return newID;
}


const SaveMessage = ({ visible }) => (
  <div className={'saved' + (visible ? ' saved-visible' : '')}>
    <p>
      <Icon color='orange' size='large' name='save outline' />
      Saved
    </p>
  </div>
)


const ErrorMessage = (props) => (
  <Message error>
    <Message.Header>Error</Message.Header>
    <p>{props.children}</p>
  </Message>
)




const artStatusOptions = [
  { key: '0', text: 'Draft', value: false },
  { key: '1', text: 'Published', value: true }
];


/**
 * props: articleId, langVersionId, getCollectionList(), toggleArticleLoading()
 */
export default class ArticleEditor extends React.Component {
  user = JSON.parse(localStorage.getItem('user'))?.user;
  state = {
    article: {},
    title: '',
    content: '',
    colId: null,
    published: false,
    isVisibleDelete: false,
    editing: false,
    saved: false,
    timeout: null,
    editor: null,
    error: false,
    errorMessage: ''
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({
      timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000)),
      [name]: value
    });
  };


  saveValue = () => {
    this.setState({ saved: true });
    this.editArticle();
    setTimeout(() => this.setState({ saved: false }), 1000);
  };


  saveContent = () => {
    this.state.editor.save()
      .then((outputData) => {
        return axios.put(`/articles/${this.state.article.id}`, { content: JSON.stringify(outputData) })
          .then(res => {
            console.log('saved');
            this.setState({ saved: true });
            setTimeout(() => this.setState({ saved: false }), 1000);
          })
          .catch(err => { console.log('not saved') });
      }).catch((error) => {
        console.log('Saving failed: ', error);
      });
  }


  getArticle = id => {
    this.props.toggleArticleLoading(true);
    // BURAYA CHECK KOY EDITOR GERCEKTEN EDITORJS OBJESI MI
    if (this.state.editor) {
      try {
        this.state.editor.destroy();
      } catch (e) {
        // EDITOR HATALI ISE ERROR ILE HANDLE ET (MESAJ GOSTER SAYFAYI YENILE DE ???)
        console.error('The editor encountered an error. Please reload the page and try again.');
        this.setState({ 
          error: true, 
          errorMessage: 'The editor encountered an error. Please reload the page and try again.' 
        });
        console.log(e);
      } finally {
        this.setState({ editor: null });
      }
    }

    return axios.get(`/articles/${id}`)
      .then(res => {
        const article = res.data;
        this.setState({
          content: article.content,
          article: article,
          title: article.title,
          colId: article.col.id,
          published: article.published,
          editing: false,
          error: false
        }, () => {
          this.setState({
            editor: this.createEditor(),
          })
        });
      })
      .catch(err => {
        console.log('getArticle failed', err);
        console.error('There was an error when retrieving the article.');
        this.setState({ 
          error: true, 
          errorMessage: 'There was an error when retrieving the article.'
        });
        // USTTEKINE BENZER BIR ERROR HANDLING MESAJ FALAN KOY
      })
      .finally(() => this.props.toggleArticleLoading(false));
  }


  deleteArticle = () => {
    return axios.delete(`/articles/${this.state.article.id}`)
      .then(res => {
        this.setState({
          article: {},
          content: '',
          title: '',
          colId: null,
          published: false
        });

        this.props.getCollectionList(this.props.langVersionId);
      })
      .catch(err => {
        console.log('deleteArticle failed', err);
      })
      .finally(() => this.closeDeleteArticleModal());
  }


  editArticle = () => {
    const article = {
      title: this.state.title,
      col: this.state.colId,
      published: this.state.published,
      updatedBy: this.user.id
    };

    return axios.put(`/articles/${this.state.article.id}`, article)
      .then(res => {
        this.props.getCollectionList(this.props.langVersionId);
      })
      .catch(err => {
        console.log('editArticle failed', err);
      });
  }


  changeStatus = (event, data) => {
    this.setState({ published: data.value }, this.editArticle);
  }


  startEditing = async () => {
    const { data: selectedArticle } = await axios.get(`/articles/${this.state.article.id}`);
    const editingUser = selectedArticle.editing;

    if (!editingUser || editingUser.id === this.user.id) {
      return axios.put(`/articles/${this.state.article.id}`, { editing: this.user.id })
        .then(res => {
          if (this.state.editor) this.state.editor.destroy();
          this.setState({ editing: true, editor: this.createEditor(false) }, );
        })
    } else if (editingUser.id === this.user.id) {
      if (this.state.editor) this.state.editor.destroy();
      this.setState({ editing: true, editor: this.createEditor(false)});
    } else {
      alert(`This article is currently being edited by ${editingUser.firstName + ' ' + editingUser.lastName}`);
    }
  }


  stopEditing = (articleId) => {
    return axios.put(`/articles/${articleId}`, { editing: null })
      .then(res => {
        this.setState({ editing: false });
        this.state.editor.readOnly && this.state.editor.readOnly.toggle(true);
      })
  }


  toggleEdit = () => {
    if (this.state.editing) {
      this.stopEditing(this.state.article.id);
    } else {
      this.startEditing();
    }
  }


  createEditor = (parameter) => {
    return new EditorJS({
      holder: 'editorjs',
      placeholder: 'Type here...',
      readOnly: parameter ?? !this.state.editing,
      data: JSON.parse(this.state.content),
      onChange: this.saveContent,
      tools: {
        header: {
          class: Header,
          shortcut: 'CMD+SHIFT+H',
          config: {
            placeholder: 'Enter a header',
            levels: [2, 3],
            defaultLevel: 2
          }
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file) {
                const formData = new FormData();
                formData.append("files", file);

                return axios.post('/upload', formData)
                  .then(res => {
                    return {
                      success: 1,
                      file: {
                        url: res.config.baseURL + res.data[0].url
                      }
                    };
                  })
                  .catch(error => {
                    console.error("Error:", error);
                    return { success: 0, file: { url: '' } };
                  });
              }
            }
          }
        },
        list: {
          class: List,
          inlineToolbar: true
        },
        code: CodeTool
      }
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.articleId !== this.props.articleId) {
      if (prevState.editing) {
        this.stopEditing(prevState.article.id);
      }

      this.getArticle(this.props.articleId)
        .then(() => this.setState({ timeout: null }));
    }
  }


  componentWillUnmount() {
    if (this.state.editing) {
      axios.put(`/articles/${this.state.article.id}`, { editing: null });
    }
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.getArticle(this.props.articleId)
      .then(() => this.setState({
        timeout: null
      }));
  }


  render() {
    const disabled = !this.state.article.id;
    const firstNameCreate = this.state.article.createdBy ? this.state.article.createdBy.firstName : '';
    const lastNameCreate = this.state.article.createdBy ? this.state.article.createdBy.lastName : '';
    const firstNameUpdate = this.state.article.updatedBy ? this.state.article.updatedBy.firstName : '';
    const lastNameUpdate = this.state.article.updatedBy ? this.state.article.updatedBy.lastName : '';
    const dateCreate = this.state.article.createdAt ? moment(this.state.article.createdAt).format("DD.MMM.YYYY, HH:mm") : '';
    const dateUpdate = this.state.article.updatedAt ? moment(this.state.article.updatedAt).format("DD.MMM.YYYY, HH:mm") : '';


    return (
      !this.state.error ?
      <>
        <div className='article-breadcrumb' >
          <Dropdown placeholder='Article Status' disabled={!this.state.editing}
            options={artStatusOptions} name="published"
            onChange={this.changeStatus} value={this.state.published} id='article-status-dd'
          />
          <Radio toggle label={this.state.editing ? 'Done' : 'Edit'} checked={this.state.editing} onChange={this.toggleEdit}
            disabled={!this.state.article.id || this.state.editor === null} />
          {this.state.article.createdBy &&
            <span>Created by: <em>{firstNameCreate + ' ' + lastNameCreate}</em> on <em>{dateCreate}</em></span>
          }
          {this.state.article.updatedBy &&
            <span>Updated by: <em>{firstNameUpdate + ' ' + lastNameUpdate}</em> on <em>{dateUpdate}</em></span>
          }
        </div>
        <Input
          id='article-title-input'
          name='title'
          transparent
          fluid
          size='massive'
          placeholder='Article Title'
          disabled={disabled || !this.state.editing}
          value={this.state.title}
          onChange={this.handleInputChange}
        />

        <div id='editorjs'></div>

        <SaveMessage visible={this.state.saved} />
      </> : 
      <ErrorMessage>There was an error when retrieving the article.</ErrorMessage>
    )
  }
}
