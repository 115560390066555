import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { Grid, Dimmer, Icon } from 'semantic-ui-react';
import PageHeader from '../../layout/PageHeader';
import AdminTicketStatsTable from './components/AdminTicketStatsTable';
import './ticket.css';



const AdminTicketStatsPage = (props) => {
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'On Going', columns: [
          { Header: 'In Progress', accessor: 'inProgress' },
          { Header: 'Fix Planned', accessor: 'fixPlanned' },
          { Header: 'Waiting For Customer', accessor: 'waitingForCustomer' },
        ]
      },
      {
        Header: 'Closed', columns: [
          { Header: 'Fixed', accessor: 'fixed' },
          { Header: 'No Fix Planned', accessor: 'noFixPlanned' },
          { Header: 'Not Supported', accessor: 'notSupported' }
        ]
      },
      { Header: 'Total', accessor: 'total' }
    ],
    []
  );


  const getAdminTicketStats = () => {
    setLoading(true);
    return axios.get('/tickets/adminstats')
      .then(res => {
        setAdminList(res.data);
      })
      .catch(err => console.log('getAdminTicketStats failed', err))
      .finally(() => setLoading(false))
  }


  useEffect(() => {
    getAdminTicketStats();
  }, []);


  return (
    <Grid>
      <PageHeader title='Admin Ticket Stats' description='Number of assigned tickets and their status for each admin' icon='address card outline'>
      </PageHeader>

      <Grid.Row className='page-content'>
        <Grid.Column>
          <Dimmer active={loading} inverted >
            <Icon name='spinner' loading size='huge' color='blue' />
          </Dimmer>

          <AdminTicketStatsTable columns={columns} data={adminList} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default AdminTicketStatsPage;
