import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Divider, Form, Button, Message, List } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import '../ticket.css';



const CommentInput = (props) => {
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [deletedAtt, setDeletedAtt] = useState([]);
  const [newAtt, setNewAtt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const handleContentChange = (event) => {
    setContent(event.target.value);
  }


  const onDrop = (files) => {
    setNewAtt(prevState => prevState.concat(files));
    setAttachments(prevState => prevState.concat(files));
  }


  const deleteFile = (filename) => {
    if (props.isEdit) {
      const deletedFile = attachments.find(f => f.name === filename);
      setDeletedAtt(prevState => [...prevState, deletedFile.id]);
    }

    let prevFileList = attachments;
    setAttachments(prevFileList.filter(f => f.name !== filename));
  }


  const addComment = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (attachments.length) {
      const formData = new FormData();

      const data = {
        ticket: props.ticket.id,
        content: content,
        type: 'comment'
      };

      for (let i = 0; i < attachments.length; ++i) {
        const file = attachments[i];
        formData.append('files.attachments', file, file.name);
      }

      formData.append('data', JSON.stringify(data));

      return axios.post('/ticketcomments', formData)
        .then(res => {
          props.getComments(props.ticket.id);
          props.getTicket(props.ticket.id);
          setContent('');
          setAttachments([]);
        })
        .catch(err => console.log('addComment failed', err))
        .finally(() => setIsLoading(false));
    } else {
      const newComment = {
        ticket: props.ticket.id,
        content: content,
        type: 'comment',
      };

      return axios.post('/ticketcomments', newComment)
        .then(res => {
          props.getComments(props.ticket.id);
          props.getTicket(props.ticket.id);
          setContent('');
        })
        .catch(err => console.log('addComment failed', err))
        .finally(() => setIsLoading(false));
    }
  }


  const editComment = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // if attachments added
    if (newAtt.length) {
      const formData = new FormData();
      const data = {
        content: content,
        deletedAtt: deletedAtt
      };

      for (let i = 0; i < newAtt.length; ++i) {
        const file = newAtt[i];
        formData.append('files.attachments', file, file.name);
      }

      formData.append('data', JSON.stringify(data));

      return axios.put(`/ticketcomments/${props.comment.id}`, formData)
        .then(res => {
          Promise.all([props.getComments(props.ticket.id), props.getTicket(props.ticket.id)])
            .then(() => {
              setContent('');
              setAttachments([]);
              setNewAtt([]);
              setDeletedAtt([]);
              props.setEditCommentId(null);
            })
        })
        .catch(err => console.log('editComment failed', err))
        .finally(() => setIsLoading(false));
    } else {
      const updatedComment = {
        content: content,
        deletedAtt: deletedAtt
      };

      return axios.put(`/ticketcomments/${props.comment.id}`, updatedComment)
        .then(res => {
          Promise.all([props.getComments(props.ticket.id), props.getTicket(props.ticket.id)])
            .then(() => {
              setContent('');
              setAttachments([]);
              setDeletedAtt([]);
              props.setEditCommentId(null);
            })
        })
        .catch(err => console.log('editComment failed', err))
        .finally(() => setIsLoading(false));
    }
  }


  useEffect(() => {
    if (props.isEdit) {
      setContent(props.comment.content);
      setAttachments(props.comment.attachments);
      setNewAtt([]);
      setDeletedAtt([]);
    }
  }, []);


  return (
    <Form onSubmit={props.isEdit ? editComment : addComment} >
      <Divider />
      <Form.TextArea rows={5} value={content} onChange={handleContentChange} name='content' placeholder='Write a comment' />
      <Dropzone fluid onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Message
                size='tiny'
                icon='inbox'
                header='Upload files'
              />
            </div>
            <aside>
              <List id='comment-attachment-list' divided verticalAlign='middle'>
                {attachments.map(file => (
                  <List.Item key={file.name}>
                    <List.Content floated='right'>
                      <Button type="button" icon='trash' onClick={() => deleteFile(file.name)} />
                    </List.Content>
                    <List.Content>{file.name} - {file.size} bytes</List.Content>
                  </List.Item>
                ))}
              </List>
            </aside>
          </section>
        )}
      </Dropzone>

      <Form.Group>
        <Form.Button color='green' disabled={!content || isLoading} loading={isLoading}>
          Comment
        </Form.Button>
        {props.isEdit &&
          <Form.Button type="button" onClick={() => props.setEditCommentId(null)} disabled={isLoading}>
            Cancel
          </Form.Button>
        }
      </Form.Group>
    </Form>
  );
}

export default CommentInput;
