import React from 'react';
import axios from 'axios';
import { Modal, Button, Form, Dropdown, Icon } from 'semantic-ui-react';



export default class AssignTicketModal extends React.Component {
  state = {
    isOpen: false,
    adminsDD: [],
    assignedTo: null
  }


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  openModal = () => {
    this.setState({
      isOpen: true,
      assignedTo: this.props.ticket.assignedTo?.id
    });
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  }


  getAdmins = () => {
    return axios.get('/users?type=authenticated')
      .then(res => {
        const adminsDD = res.data.map(user => (
          { key: user.id, text: user.firstName + ' ' + user.lastName, value: user.id }
        ));
        this.setState({ adminsDD: adminsDD });
      })
      .catch(err => console.log('getAdmins failed', err));
  }


  assignTicket = () => {
    const updatedTicket = {
      assignedTo: this.state.assignedTo,
      status: 'inProgress'
    };

    return axios.put(`/tickets/assign/${this.props.ticket.id}`, updatedTicket)
      .then(res => {
        this.props.updateTicket(res.data);
        this.props.getComments(res.data.id);
        this.closeModal();
      })
      .catch(err => console.log('assignTicket failed', err));
  }


  componentDidMount() {
    this.getAdmins();
  }


  render() {
    return (
      <Modal open={this.state.isOpen} onOpen={this.openModal} onClose={this.closeModal} size='tiny'
        trigger={<Dropdown.Item><Icon name='user outline' />Assign Ticket</Dropdown.Item>}>
        <Modal.Header>Assign Ticket</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Select fluid required label='Assign to' placeholder='Select Admin' options={this.state.adminsDD}
              value={this.state.assignedTo} onChange={this.handleInputChange} name='assignedTo' />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.assignTicket} disabled={!this.state.assignedTo} >
            Submit
          </Button>
          <Button onClick={this.closeModal} >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}