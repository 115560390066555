import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Divider, Header, Image, Icon } from 'semantic-ui-react';
import { imgExtensions } from '../../../constants';
import fileImg from '../../../images/file.jpg';
import '../ticket.css';



const RegularComment = (props) => {
  const name = props.comment.postedBy.firstName + ' ' + props.comment.postedBy.lastName;
  const openDate = moment(props.comment.createdAt).format("DD.MMM.YYYY, HH:mm");
  const message = <span><strong>{name}</strong> commented on {openDate}</span>;

  return (
    <div className='comment-segment'>
      {message}
      {props.user.id === props.comment.postedBy?.id &&
        <Icon className='edit-comment-button' name='edit' onClick={() => props.setEditCommentId(props.comment.id)} />
      }
      <Divider />
      {props.comment.content}
      {
        props.comment.attachments && !!props.comment.attachments.length &&
        <Header.Content className='comment-attachments-header'>
          <h3>Attachments</h3>
          <Header.Subheader className='comment-attachments-subheader'>
            <Image.Group size='small'>
              {props.comment.attachments.map(file => {
                return (
                  imgExtensions.has(file.ext.toLowerCase()) ?
                    <a href={axios.defaults.baseURL + file.url} target="_blank" rel="noopener noreferrer" key={file.id} >
                      <Image bordered src={axios.defaults.baseURL + file.url} className="custom-image" />
                    </a> :
                    <a href={axios.defaults.baseURL + file.url} target="_blank" rel="noopener noreferrer" key={file.id} >
                      <Image bordered src={fileImg} className="custom-image" label={file.name} />
                    </a>
                )
              })}
            </Image.Group>
          </Header.Subheader>
        </Header.Content>
      }
    </div>
  );
}

export default RegularComment;
