
import React from 'react';
import axios from 'axios';
import Interweave from 'interweave';



const HeaderBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        {
          props.level === 2 ?
            <h2 className='ce-header'>
              <Interweave content={props.text} tagName='span' />
            </h2> :
            <h3 className='ce-header'>
              <Interweave content={props.text} tagName='span' />
            </h3>
        }
      </div>
    </div>
  );
}


const ParagraphBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='ce-paragraph cdx-block'>
          <Interweave content={props.text} tagName='span' />
        </div>
      </div>
    </div>
  );
}


const ImageBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='cdx-block image-tool image-tool--filled'>
          <div className='image-tool__image'>
            <a href={props.url} target="_blank" rel='noopener noreferrer'>
              <img className='image-tool__image-picture' src={props.url} alt={props.caption} />
            </a>
          </div>
          <div className='cdx-input image-tool__caption'>
            <Interweave content={props.caption} tagName='span' />
          </div>
        </div>
      </div>
    </div>
  );
}


const UnorderedListBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <ul className='cdx-block cdx-list cdx-list--unordered'>
          {
            props.items.map(item => <li className='cdx-list__item'><Interweave content={item} tagName='span' /></li>)
          }
        </ul>
      </div>
    </div>
  );
}


const OrderedListBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <ol className='cdx-block cdx-list cdx-list--ordered'>
          {
            props.items.map(item => <li className='cdx-list__item'><Interweave content={item} tagName='span' /></li>)
          }
        </ol>
      </div>
    </div>
  );
}


const CodeBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='cdx-block ce-code'>
          <pre className='code-block'><code>{props.code}</code></pre>
        </div>
      </div>
    </div>
  );
}


// const TableBlock = (props) => {
//   return (
//     <div className='ce-block'>
//       <div className='ce-block__content'>
//         <div className='tc-editor cdx-block'>
//           <div className='tc-table__wrap'>
//             <table className='tc-table'>
//               <thead>
//                 <tr>
//                   {
//                     props.head.map(h => <th className='tc-table__cell'>{h}</th>)
//                   }
//                 </tr>
//               </thead>
//               <tbody>
//                 {
//                   props.body.map(row => (
//                     <tr>
//                       {
//                         row.map(val => <td className='tc-table__cell'>{val}</td>)
//                       }
//                     </tr>
//                   ))
//                 }
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }




/**
 * props: articleId, getHeaders(), toggleArticleLoading()
 */
export default class ArticleViewer extends React.Component {

  state = {
    article: {},
    blocks: [],
    content: '',
    isEditorjs: true
  };


  getArticle = (id) => {
    this.props.toggleArticleLoading(true);

    return axios.get(`/articles/${id}`)
      .then(res => {
        try {
          this.setState({
            article: res.data,
            blocks: JSON.parse(res.data.content).blocks,
            content: '',
            isEditorjs: true
          }, this.props.getHeaders);
        } catch (err) {
          this.setState({
            article: res.data,
            content: res.data.content,
            blocks: [],
            isEditorjs: false
          }, this.props.getHeaders)
        }
      })
      .catch(err => {
        console.log('getArticle failed', err);
      })
      .finally(() => this.props.toggleArticleLoading(false));
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.articleId !== this.props.articleId) {
      this.getArticle(this.props.articleId);
    }
  }


  componentDidMount() {
    this.getArticle(this.props.articleId);
  }


  render() {
    return (
      <div>
        <div id='article-viewer' >
          <h1 id='article-title-header'>
            {this.state.article.title}
          </h1>
          {this.state.isEditorjs ?
            this.state.blocks.map(block => {
              if (block.type === 'header') {
                if (block.data.level === 2) {
                  return <HeaderBlock level={2} text={block.data.text} />;
                }
                else if (block.data.level === 3) {
                  return <HeaderBlock level={3} text={block.data.text} />;
                }
                else {
                  return null;
                }
              }
              else if (block.type === 'paragraph') {
                return <ParagraphBlock text={block.data.text} />;
              }
              else if (block.type === 'image') {
                return <ImageBlock url={block.data.file.url} caption={block.data.caption} />;
              }
              else if (block.type === 'list') {
                if (block.data.style === 'unordered') {
                  return <UnorderedListBlock items={block.data.items} />;
                }
                else if (block.data.style === 'ordered') {
                  return <OrderedListBlock items={block.data.items} />;
                }
                else {
                  return null;
                }
              }
              else if (block.type === 'code') {
                return <CodeBlock code={block.data.code} />;
              }
              // else if (block.type === 'table') {
              //   return <TableBlock head={block.data.content[0]} body={block.data.content.slice(1)} />
              // }
              else {
                return null;
              }
            }) :
            <Interweave content={this.state.article.content} disableLineBreaks={true} tagName='div' />
          }
        </div>
      </div>
    )
  }
}