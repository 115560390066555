import React from 'react';
import { Icon } from 'semantic-ui-react';


export const priorityBgColorMap = {
  'low': '#008000',
  'medium': '#ffff00',
  'high': '#ffb266',
  'critical': '#ff0000'
};

export const priorityFgColorMap = {
  'low': '#fff',
  'medium': '#000',
  'high': '#000',
  'critical': '#fff'
};

export const statusTextMap = {
  'open': 'Open',
  'inProgress': 'In Progress',
  'fixPlanned': 'Planned',
  'waitingForCustomer': 'Customer',
  'closed': 'Closed'
};

export const statusIconMap = {
  'open': <Icon name='envelope open outline' />,
  'inProgress': <Icon name='cogs' style={{ marginRight: '5px' }} />,
  'fixPlanned': <Icon name='calendar check outline' />,
  'waitingForCustomer': <Icon name='hourglass half' />,
  'closed': <Icon name='lock' />
};

export const resolutionTextMap = {
  'fixed': 'Fixed',
  'noFixPlanned': 'No Fix Planned',
  'notSupported': 'Not Supported'
};

export const resolutionIconMap = {
  'fixed': <Icon name='check' color='green' />,
  'noFixPlanned': <Icon name='close' color='red' />,
  'notSupported': <Icon name='dont' />
};

export const priorityDD = [
  { key: 'low', text: 'Low', value: 'low' },
  { key: 'medium', text: 'Medium', value: 'medium' },
  { key: 'high', text: 'High', value: 'high' },
  { key: 'critical', text: 'Critical', value: 'critical' }
];

export const statusDD = [
  { key: 'open', text: 'Open', value: 'open' },
  { key: 'inProgress', text: 'In Progress', value: 'inProgress' },
  { key: 'fixPlanned', text: 'Fix Planned', value: 'fixPlanned' },
  { key: 'waitingForCustomer', text: 'Waiting For Customer', value: 'waitingForCustomer' },
  { key: 'closed', text: 'Closed', value: 'closed' }
];

export const resolutionDD = [
  { key: 'fixed', text: 'Fixed', value: 'fixed' },
  { key: 'noFixPlanned', text: 'No Fix Planned', value: 'noFixPlanned' },
  { key: 'notSupported', text: 'Not Supported', value: 'notSupported' }
];

export const ticketTypeTextMap = {
  'bug': 'Bug',
  'feature': 'Feature Request',
  'other': 'Other'
};

export const ticketTypeDD = [
  { key: 'bug', text: 'Bug', value: 'bug' },
  { key: 'feature', text: 'Feature Request', value: 'feature' },
  { key: 'other', text: 'Other', value: 'other' }
];

export const ticketIconMap = {
  'bug': <Icon name='bug' style={{ marginLeft: '5px' }}/>,
  'feature': <Icon name='cog' style={{ marginLeft: '5px' }}/>,
  'other': <Icon name='circle notched' style={{ marginLeft: '5px' }}/>
};

export const imgExtensions = new Set(['.png', '.jpg', '.jpeg', '.gif']);