import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import '../product.css';


const ProductOptionsDD = (props) => {
  return (
    <Dropdown compact item icon='ellipsis horizontal' direction='right' >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/product-docs/${props.product.id}`}>
          <Icon name='info' />
          View docs
        </Dropdown.Item>
        <Dropdown.Item onClick={() => props.setEditProductId(props.product.id)}>
          <Icon name='edit outline' />
          Edit Product
        </Dropdown.Item>
        <Dropdown.Item onClick={() => props.openDeleteProductModal(props.product.id)}>
          <Icon name='remove' />
          Delete Product
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProductOptionsDD;