import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Grid, Image, Input, Message } from 'semantic-ui-react';
import { useLocation, Link } from 'react-router-dom';
import netasLogo from '../../images/netaslogo.png';
import visiumLogo from '../../images/VisiumGoLogo.png';
import './login.css';


const checkPassword = (password) => {
  if (password.length < 8) {
    return false;
  } else if (!(/\d/.test(password))) {  // contains a number
    return false;
  } else if (!(/[a-zA-Z]/.test(password))) { // contains a letter
    return false;
  } else {
    return true;
  }
}


const ResetPasswordPage = () => {
  let location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isVisibleError, setIsVisibleError] = useState(false);
  const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const onSubmit = () => {
    setIsLoading(true);
    setIsDisabled(true);
    setIsVisibleError(false);

    if (password !== passwordConfirmation) {
      setErrorMessage('Passwords do not match');
      setIsVisibleError(true);
      setIsLoading(false);
      setIsDisabled(false);
    } else if (!checkPassword(password)) {
      setErrorMessage('Password must have at least 8 characters and contain at least one letter and one number');
      setIsVisibleError(true);
      setIsLoading(false);
      setIsDisabled(false);
    } else {
      axios.post('/auth/reset-password', {
        "password": password,
        "passwordConfirmation": passwordConfirmation,
        "code": code
      }).then(res => {
        setIsVisibleSuccess(true);
        setIsLoading(false);
        console.log('res', res);
      }).catch(err => {
        setErrorMessage('Password reset failed.');
        setIsVisibleError(true);
        setIsLoading(false);
        setIsDisabled(false);
        console.log(err);
      });
    }
  };

  return (
    <div>
      <Grid id='login-grid' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Image id='visium-logo' src={visiumLogo} centered />
          <Form id='login-form' size='large' error>
            <div>
              <Form.Field>
                <Input required type='password' size='big' fluid name="password" icon='lock' iconPosition='left'
                  placeholder='Password' value={password}
                  onChange={event => {
                    setPassword(event.target.value)
                  }} />
                <Input required type='password' size='big' fluid name="passwordConfirmation" icon='lock' iconPosition='left'
                  placeholder='Confirm Password' value={passwordConfirmation}
                  onChange={event => {
                    setPasswordConfirmation(event.target.value)
                  }} />
              </Form.Field>

              <Message error hidden={!isVisibleError}>
                {errorMessage}
              </Message>

              <Message success visible={isVisibleSuccess}>
                Your password has been reset.<br />
                <Link to='/login' >Go to the login page</Link>
              </Message>

              <Button primary fluid size='huge' onClick={onSubmit} loading={isLoading} disabled={isDisabled}>
                Submit
              </Button>
            </div>
          </Form>

          <Image id='netas-logo' src={netasLogo} centered />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ResetPasswordPage;