import React from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { resolutionTextMap } from '../../../constants';
import '../ticket.css';


const LogComment = (props) => {
  const name = props.comment.postedBy.firstName + ' ' + props.comment.postedBy.lastName;
  const openDate = moment(props.comment.createdAt).format("DD.MMM.YYYY, HH:mm");
  let message, icon;

  if (props.comment.logType === 'open') {
    message = <span><strong>{name}</strong> opened this ticket on {openDate}</span>;
    icon = <Icon name='envelope open outline' color='green' />;
  }
  else if (props.comment.logType === 'assign') {
    const assignedToName = props.comment.assignedTo.firstName + ' ' + props.comment.assignedTo.lastName;
    message = <span><strong>{name}</strong> assigned this ticket to <strong>{assignedToName}</strong> on {openDate}</span>;
    icon = <Icon name='user outline' color='blue' />;
  }
  else if (props.comment.logType === 'edit') {
    message = <span><strong>{name}</strong> edited this ticket on {openDate}</span>;
    icon = <Icon name='edit outline' color='orange' />;
  }
  else {
    message = <span><strong>{name}</strong> closed this ticket on {openDate} as <strong>{resolutionTextMap[props.comment.resolution]}</strong></span>;
    icon = <Icon name='lock' color='red' />;
  }

  return (
    <div style={{padding:20}}>
      {icon} {message}
    </div>
  );
}

export default LogComment;
