import React from 'react';
import axios from 'axios';
import { Modal, Dropdown, Icon, Form, Button } from 'semantic-ui-react';
import { statusDD, resolutionDD } from '../../../constants';



export default class ChangeStatusModal extends React.Component {
  state = {
    isOpen: false,
    status: '',
    resolution: '',
    fixVersion: '',
    fixDate: ''
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  openModal = () => {
    this.setState({
      isOpen: true,
      status: this.props.ticket.status,
      resolution: this.props.ticket.resolution,
      fixVersion: this.props.ticket.estimatedFixVer || '',
      fixDate: this.props.ticket.estimatedFixDate || ''
    });
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  }


  updateStatus = () => {
    const updatedTicket = {
      status: this.state.status,
      ... (this.state.status === 'closed' ? { resolution: this.state.resolution, closeDate: new Date() } : {}),
      ... (this.state.status === 'open' ? { assignedTo: null } : {}),
    };

    if (this.state.status === 'fixPlanned') {
      updatedTicket.estimatedFixVer = this.state.fixVersion;
      updatedTicket.estimatedFixDate = this.state.fixDate;
    }

    return axios.put(`/tickets/updatestatus/${this.props.ticket.id}`, updatedTicket)
      .then(res => {
        this.props.updateTicket(res.data);
        this.props.getComments(res.data.id);
        this.closeModal();
      })
      .catch(err => console.log('updateStatus failed', err));
  }


  render() {
    return (
      <Modal open={this.state.isOpen} onOpen={this.openModal} onClose={this.closeModal} size='small'
        trigger={<Dropdown.Item><Icon name='tag' />Change Ticket Status</Dropdown.Item>}>

        <Modal.Header>Change Ticket Status</Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Select fluid required label='Status' placeholder='Select Status' options={statusDD}
              value={this.state.status} onChange={this.handleInputChange} name='status' />
            {this.state.status === 'fixPlanned' &&
              <Form.Group widths='equal'>
                <Form.Input fluid required label='Estimated Fix Version' value={this.state.fixVersion} onChange={this.handleInputChange} name='fixVersion' />
                <Form.Input fluid required type='date' label='Estimated Fix Date' value={this.state.fixDate} onChange={this.handleInputChange} name='fixDate' />
              </Form.Group>
            }
            {this.state.status === 'closed' &&
              <Form.Select fluid required label='Resolution' placeholder='Select Resolution' options={resolutionDD}
                value={this.state.resolution} onChange={this.handleInputChange} name='resolution' />
            }
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button primary onClick={this.updateStatus} 
            disabled={!this.state.status || 
                      (this.state.status === 'closed' && !this.state.resolution) ||
                      (this.state.status === 'fixPlanned' && (!this.state.fixVersion || !this.state.fixDate))} >
            Submit
          </Button>
          <Button onClick={this.closeModal} >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
