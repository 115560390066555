import React from 'react';
import axios from 'axios';
import { Modal, Dropdown, Icon, Form, Button } from 'semantic-ui-react';
import { priorityDD } from '../../../constants';



export default class ChangeStatusModal extends React.Component {
  state = {
    isOpen: false,
    priority: ''
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  openModal = () => {
    this.setState({
      isOpen: true,
      priority: this.props.ticket.priority || '',
    });
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  }


  updatePriority = () => {
    return axios.put(`/tickets/changePriority/${this.props.ticket.id}`, { priority: this.state.priority })
      .then(res => {
        this.props.updateTicket(res.data);
        this.props.getComments(res.data.id);
        this.closeModal();
      })
      .catch(err => console.log('updatePriority failed', err));
  }


  render() {
    return (
      <Modal open={this.state.isOpen} onOpen={this.openModal} onClose={this.closeModal} size='small'
        trigger={<Dropdown.Item><Icon name='bolt' />Change Priority</Dropdown.Item>}>

        <Modal.Header>Change Ticket Priority</Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Select fluid required label='Priority' placeholder='Select Priority' options={priorityDD}
              value={this.state.priority} onChange={this.handleInputChange} name='priority' />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button primary onClick={this.updatePriority} disabled={!this.state.priority} >
            Submit
          </Button>
          <Button onClick={this.closeModal} >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}