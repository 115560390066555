import React from 'react';
import axios from 'axios';
import { Grid, Form, Message, List, Button, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import PageHeader from '../../layout/PageHeader';
import { priorityDD, ticketTypeDD } from '../../constants';
import './ticket.css';
import { Link } from 'react-router-dom';



export default class TicketInputPage extends React.Component {
  ticketId = this.props.isEdit ? this.props.match.params.id : null;
  userStr = localStorage.getItem("user");
  userRole = JSON.parse(this.userStr) && JSON.parse(this.userStr).user.role.type;
  user = JSON.parse(this.userStr).user;

  state = {
    ticket: {},
    title: '',
    description: '',
    priority: '',
    productId: null,
    versionNo: '',
    type: '',
    attachments: [],
    newAtt: [],
    deletedAtt: [],
    productList: [],
    isLoading: false,
    isDisabled: false
  }


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };


  getProducts = () => {
    return axios.get('/products')
      .then(res => {
        this.setState({ productList: res.data });
      })
      .catch(err => console.log('getProducts failed\n', err));
  }


  onDrop = (files) => {
    this.setState(prevState => ({
      attachments: prevState.attachments.concat(files),
      newAtt: prevState.newAtt.concat(files)
    }))
  }


  deleteFile = (filename) => {
    if (this.props.isEdit) {
      const deletedFile = this.state.attachments.find(f => f.name === filename);
      this.setState(prev => ({ deletedAtt: prev.deletedAtt.concat(deletedFile.id) }));
    }

    this.setState(prev => ({ attachments: prev.attachments.filter(f => f.name !== filename) }))
  }


  addTicket = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      isDisabled: true
    });

    if (this.state.attachments.length) {
      const formData = new FormData();
      const data = {
        title: this.state.title,
        description: this.state.description,
        product: this.state.productId,
        versionNo: this.state.versionNo,
        priority: this.state.priority,
        type: this.state.type,
        status: 'open'
      };

      for (let i = 0; i < this.state.attachments.length; ++i) {
        const file = this.state.attachments[i];
        formData.append('files.attachments', file, file.name);
      }

      formData.append('data', JSON.stringify(data));

      return axios.post('/tickets', formData)
        .then(res => {
          this.props.history.push('/ticket-list');
        })
        .catch(err => {
          console.log('addTicket failed', err);
          this.setState({
            isLoading: false,
            isDisabled: false
          });
        });
    } else {
      const newTicket = {
        title: this.state.title,
        description: this.state.description,
        product: this.state.productId,
        versionNo: this.state.versionNo,
        priority: this.state.priority,
        type: this.state.type,
        status: 'open'
      };

      return axios.post('/tickets', newTicket)
        .then(res => {
          this.props.history.push('/ticket-list');
        })
        .catch(err => {
          console.log('addTicket failed', err);
          this.setState({
            isLoading: false,
            isDisabled: false
          });
        });
    }
  }


  getTicket = (id) => {
    return axios.get(`/tickets/${id}`)
      .then(res => {
        this.setState({
          ticket: res.data,
          title: res.data.title,
          description: res.data.description,
          priority: res.data.priority,
          type: res.data.type,
          attachments: res.data.attachments
        });
      })
      .catch(err => console.log('getTicket failed', err));
  }


  editTicket = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      isDisabled: true
    });

    if (this.state.newAtt.length > 0) {
      const formData = new FormData();
      const data = {
        title: this.state.title,
        description: this.state.description,
        priority: this.state.priority,
        type: this.state.type,
        deletedAtt: this.state.deletedAtt
      };

      for (let i = 0; i < this.state.newAtt.length; ++i) {
        const file = this.state.newAtt[i];
        formData.append('files.attachments', file, file.name);
      }

      formData.append('data', JSON.stringify(data));

      return axios.put(`/tickets/${this.state.ticket.id}`, formData)
        .then(res => {
          this.props.history.push(`/ticket-details/${this.state.ticket.id}`);
        })
        .catch(err => {
          console.log('editTicket failed', err);
          this.setState({
            isLoading: false,
            isDisabled: false
          });
        });
    } else {
      const updatedTicket = {
        title: this.state.title,
        description: this.state.description,
        priority: this.state.priority,
        type: this.state.type,
        deletedAtt: this.state.deletedAtt
      };

      return axios.put(`/tickets/${this.state.ticket.id}`, updatedTicket)
        .then(res => {
          this.props.history.push(`/ticket-details/${this.state.ticket.id}`);
        })
        .catch(err => {
          console.log('editTicket failed', err);
          this.setState({
            isLoading: false,
            isDisabled: false
          });
        });
    }
  }


  componentDidMount() {
    if (this.props.isEdit) {
      this.getTicket(this.ticketId);
    } else {
      this.getProducts();
    }
  }


  render() {
    let productsDD = [];
    let versionsDD = [];
    if (!this.props.isEdit) {
      const selectedProduct = this.state.productList.find(p => p.id === this.state.productId);

      // the dropdown options for products
      productsDD = this.state.productList.map(prod => (
        { key: prod.id, text: prod.name, value: prod.id }
      ));

      // the dropdown options for versions
      if (selectedProduct) {
        versionsDD = selectedProduct.versions.map(ver => (
          { key: ver.id, text: ver.versionNo, value: ver.versionNo }
        ));
      }
    }

    // disable submit button if any input is missing
    let missingInput;
    if (this.props.isEdit) {
      missingInput = !(this.state.title && this.state.type && this.state.priority);
    } else {
      missingInput = !(this.state.title && this.state.productId && this.state.versionNo && this.state.type && this.state.priority);
    }


    return (
      <Grid>
        <PageHeader title={this.props.isEdit ? 'Edit Ticket' : 'New Ticket'} description="Please fill the form " icon='sticky note outline'>
          <Button icon as={Link} to='/ticket-list' >
            <Icon name='arrow left' />
            Back to list
              </Button>
        </PageHeader>
        <Grid.Row className='page-content'>
          <Grid.Column>
            <Form id='ticket-input-form' onSubmit={this.props.isEdit ? this.editTicket : this.addTicket}>
              <Form.Group>
                <Form.Input required label='Title' width={16} value={this.state.title} onChange={this.handleInputChange} name='title' />
              </Form.Group>
              <Form.TextArea label='Description' rows={10} value={this.state.description} onChange={this.handleInputChange} name='description' />
              {
                this.props.isEdit ?
                  <Form.Group>
                    <Form.Select fluid width={8} required label='Priority' placeholder='Select Priority' options={priorityDD}
                      value={this.state.priority} onChange={this.handleInputChange} name='priority' />
                    <Form.Select fluid width={8} required label='Type' placeholder='Select Type' options={ticketTypeDD}
                      value={this.state.type} onChange={this.handleInputChange} name='type' />
                  </Form.Group> :
                  <Form.Group  >
                    <Form.Select fluid width={4} required label='Product' placeholder='Select Product' options={productsDD}
                      value={this.state.productId} onChange={this.handleInputChange} name='productId' />

                    <Form.Select fluid width={4} required label='Version' placeholder='Select Version' options={versionsDD}
                      value={this.state.versionNo} onChange={this.handleInputChange} name='versionNo' disabled={!this.state.productId} />

                    <Form.Select fluid width={4} required label='Type' placeholder='Select Type' options={ticketTypeDD}
                      value={this.state.type} onChange={this.handleInputChange} name='type' />

                    <Form.Select fluid width={4} required label='Priority' placeholder='Select Priority' options={priorityDD}
                      value={this.state.priority} onChange={this.handleInputChange} name='priority' />

                  </Form.Group>
              }

              <Dropzone fluid onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <Message
                        className="upload-container"
                        icon='inbox'
                        header='Upload files'
                      />
                    </div>
                    <aside >
                      <List id='ticket-attachment-list' divided verticalAlign='middle'>
                        {this.state.attachments.map(file => (
                          <List.Item key={file.name}>
                            <List.Content floated='right'>
                              <Button icon='trash' size='tiny' onClick={() => this.deleteFile(file.name)} />
                            </List.Content>
                            <List.Content>{file.name} - {file.size} bytes</List.Content>
                          </List.Item>
                        ))}
                      </List>
                    </aside>
                  </section>
                )}
              </Dropzone>

              <Form.Button size='big' color='orange' fluid loading={this.state.isLoading} disabled={this.state.isDisabled || missingInput}>
                Submit
              </Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}