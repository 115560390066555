import React from 'react';
import axios from 'axios';
import PageHeader from '../../layout/PageHeader';
import './user.css';
import { Button, Form, Grid, Message } from 'semantic-ui-react';



const checkPassword = (password) => {
  if (password.length < 8) {
    return false;
  } else if (!(/\d/.test(password))) {  // contains a number
    return false;
  } else if (!(/[a-zA-Z]/.test(password))) { // contains a letter
    return false;
  } else {
    return true;
  }
}



export default class FirstLoginPage extends React.Component {
  userStr = localStorage.getItem("user");
  userObj = JSON.parse(this.userStr);
  user = this.userObj.user;

  state = {
    password: '',
    confirmPassword: '',
    isVisibleError: false,
    errorMessage: '',
    isLoading: false,
    isDisabled: false
  };


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };


  changePassword = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      isDisabled: true
    });

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        errorMessage: 'Passwords do not match',
        isVisibleError: true,
        isLoading: false,
        isDisabled: false
      });
      return null;
    } else if (!checkPassword(this.state.password)) {
      this.setState({
        errorMessage: 'Password must have at least 8 characters and contain at least one letter and one number',
        isVisibleError: true,
        isLoading: false,
        isDisabled: false
      });
      return null;
    } else {
      const newUserInfo = { password: this.state.password };

      return axios.put('/users/firstLogin', newUserInfo)
        .then(res => {
          this.userObj.user = res.data;
          localStorage.setItem('user', JSON.stringify(this.userObj));
          window.location.reload();
        })
        .catch(err => {
          console.log('changePassword failed', err);
          this.setState({
            errorMessage: 'Password change failed',
            isVisibleError: true,
            isLoading: false,
            isDisabled: false
          });
        });
    }
  }


  render() {
    return (
      <Grid style={{ marginTop: 0, }}>
        <PageHeader title='Account Setup' description="Initial configurations for your account" icon='user outline'>
        </PageHeader>
        <Grid.Row className='page-content'>
          <Grid.Column>
            <Form id='first-login-form' onSubmit={this.changePassword}>
              <Form.Input className='centered-form-element' size='big' required type='password' label='New Password' width={8} value={this.state.password} placeholder='New password'
                onChange={this.handleInputChange} name='password' />

              <Form.Input className='centered-form-element' size='big' required type='password' label='Confirm Password' width={8} value={this.state.confirmPassword} placeholder='Confirm password'
                onChange={this.handleInputChange} name='confirmPassword' />

              <Message className='centered-form-element' id='password-error-message' error visible={this.state.isVisibleError}>
                {this.state.errorMessage}
              </Message>

              <Form.Field as={Button} size='huge' primary className='centered-form-element'
                loading={this.state.isLoading} disabled={this.state.isDisabled}>
                Submit
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
