import React from 'react';
import axios from 'axios';
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react';



export default class VersionInputModal extends React.Component {
  state = {
    isOpen: false,
    versionNo: ''
  }


  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }


  openModal = () => {
    if (this.props.isEdit) {
      this.setState({
        isOpen: true,
        versionNo: this.props.version.versionNo
      });
    } else {
      this.setState({
        isOpen: true,
        versionNo: ''
      });
    }
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  }


  addVersion = () => {
    const latestVersion = this.props.versions.reduce((prev, current) => {
      return (prev.versionNo > current.versionNo) ? prev : current
    });

    const version = {
      versionNo: this.state.versionNo,
      product: this.props.productId,
      language: this.props.lang
    };

    return axios.post(`/versions/${latestVersion.id}`, version)
      .then(res => {
        this.props.getProduct(this.props.productId, res.data);
        this.closeModal();
      })
  }


  editVersion = () => {
    const version = { versionNo: this.state.versionNo };

    return axios.put(`/versions/${this.props.version.id}`, version)
      .then(res => {
        this.props.getProduct(this.props.productId, res.data);
        this.closeModal();
      });
  }


  render() {
    return (
      <Modal open={this.state.isOpen} onOpen={this.openModal} onClose={this.closeModal} size='tiny'
        trigger={<Dropdown.Item>{this.props.isEdit ? 'Edit Current Version' : 'Add Version'}</Dropdown.Item>}>
        <Modal.Header>{this.props.isEdit ? 'Edit Version' : 'New Version'}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input label='Version Number' name='versionNo' placeholder='Version Number'
              value={this.state.versionNo} onChange={this.handleInputChange} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.isEdit ? this.editVersion : this.addVersion} disabled={!this.state.versionNo} >
            Submit
          </Button>
          <Button onClick={this.closeModal} >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}