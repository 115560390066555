import React from 'react';
import AllRoutes from './AllRoutes';


class App extends React.Component {
  render() {
    return (
      <div>
        <AllRoutes />
      </div>
    );
  }
}

export default App;
