import React from 'react';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';


class LanguageDD extends React.Component {
  state = { langDDList: [] };


  componentDidMount() {
    return axios.get('/languages')
      .then(res => {
        const langDDList = res.data.map(lang => (
          {
            key: lang.code,
            text: lang.name,
            value: lang.code
          }
        ));
        this.setState({ langDDList: langDDList });
      })
  }


  render() {
    return (
      <Dropdown id='languageDD' className='client' selection compact direction='left' placeholder='Language'
        options={this.state.langDDList} onChange={this.props.changeLanguage} value={this.props.lang} />
    );
  }
};

export default LanguageDD;